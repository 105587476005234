import React from "react";

type MainStepProps = {
	readonly icon?: JSX.Element;
	readonly title: string;
	readonly text: string;
};

export const MainStep = ({ icon, title, text }: MainStepProps) => {
	return (
		<div className="steps__item step">
			{icon}
			{/* <IconStep1 className="step__icon"></IconStep1> */}
			<div
				className="step__title"
				dangerouslySetInnerHTML={{
					__html: title,
				}}
			></div>
			<div
				className="step__text"
				dangerouslySetInnerHTML={{
					__html: text,
				}}
			></div>
		</div>
	);
};

//     return (
//         <header id="header" className="header">
//             <div className="header__container">
//                 <div className="header__left">
//                     <img src={logo} alt="" className="header__logo" />
//                 </div>
//                 <div className="header__center">
//                     <nav className="header__nav nav">
//                         <ul className="nav__list">
//                             <li>
//                                 <CustomLink to="/">Home</CustomLink>
//                             </li>
//                             <li>
//                                 <CustomLink to="/form">Form</CustomLink>
//                             </li>
//                         </ul>
//                     </nav>
//                 </div>
//                 <div className="header__right">
//                     <div className="switcher">
//                         <Select
//                             className="react-select-container"
//                             classNamePrefix="react-select"
//                             options={languages}
//                             // defaultValue="NETHERLANDS"
//                             defaultValue={languages.find(
//                                 (e) => e.value === currentLocale
//                             )}
//                             onChange={changeLocal}
//                         ></Select>
//                         {/* Languages{" "} */}
//                         {/* <select onChange={changeLocal}>
//                             {languages.map(({ label: name, value: code }) => (
//                                 <option key={code} value={code}>
//                                     {name}
//                                 </option>
//                             ))}
//                         </select> */}
//                     </div>
//                     {/* <div className="header__switch lang-switch">
//                         <div className="lang-switch__btn">
//                             <span>NEDERLANDS</span>
//                             <i className="icon icon_chevron-down"></i>
//                         </div>
//                         <a href="&lang=nl" className="lang-switch__item">
//                             NEDERLANDS
//                         </a>
//                         <a href="&lang=ак" className="lang-switch__item">
//                             FRANÇAIS
//                         </a>
//                     </div> */}
//                 </div>
//             </div>

//             {/* <h1>
//                 Test component {name} + {year}
//             </h1>
//             <div className="wrapper">Test</div>
//             <div className="mow">test</div> */}
//         </header>
//     );
// };

// export default Header;
