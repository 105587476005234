import { isAuthenticatedSelector, tokenState } from "@app/SignIn/SignIn";
import { useSyncAtomicRef } from "@app/util/use-sync-atomic-ref";
import { useRefreshTokenMutation } from "@app/__generated__/useRefreshTokenMutation.graphql";
import { eff, fns, tsPattern } from "@shared-lib/src";
import graphql from "babel-plugin-relay/macro";
import { useEffect } from "react";
import { useMutation } from "react-relay";
import { useRecoilState, useRecoilValue } from "recoil";

export const useRefreshToken = () => {
	const isAuthenticated = useRecoilValue(isAuthenticatedSelector);
	const [token, setToken] = useRecoilState(tokenState);
	const tokenRef = useSyncAtomicRef(token);

	const [refreshToken, isRefreshTokenInFlight] =
		useMutation<useRefreshTokenMutation>(graphql`
			mutation useRefreshTokenMutation {
				auth {
					refresh {
						__typename
						... on AuthRefresh {
							token
						}
						... on AuthError {
							error
						}
					}
				}
			}
		`);

	const isRefreshTokenInFlightRef = useSyncAtomicRef(isRefreshTokenInFlight);

	useEffect(() => {
		const now = new Date();
		const sleepOneMinute = eff.fn.pipe(
			now,
			fns.differenceInMilliseconds(eff.fn.pipe(now, fns.subMinutes(1))),
			eff.t.sleep,
		);

		const sleepOneHour = eff.fn.pipe(
			now,
			fns.differenceInMilliseconds(eff.fn.pipe(now, fns.subHours(1))),
			eff.t.sleep,
		);

		const fiber = eff.fn.pipe(
			eff.t.gen(function* (_) {
				yield* _(sleepOneMinute);
				yield* _(
					eff.fn.pipe(
						eff.t.succeedWith(() => {
							if (isRefreshTokenInFlightRef.get || !isAuthenticated) {
								return;
							}

							refreshToken({
								variables: {},
								onCompleted: (data) =>
									eff.fn.pipe(
										tsPattern
											.match(data.auth.refresh)
											.with(
												{ __typename: "AuthRefresh" },
												({ token }) =>
													eff.sync.succeedWith(() => {
														setToken({
															...tokenRef.get,
															token,
														});
													}),
											)
											.with(
												{ __typename: "AuthError" },
												{ __typename: "%other" },
												() =>
													eff.sync.succeedWith(() => {
														setToken({
															...tokenRef.get,
															token: "",
														});
													}),
											)
											.exhaustive(),
										eff.sync.run,
									),
							});
						}),
						eff.t.chain(() => sleepOneHour),
						eff.t.repeat(eff.schedule.forever),
					),
				);
			}),
			eff.t.runFiber,
		);

		return () => eff.fn.pipe(fiber, eff.fiber.interrupt, eff.t.run);
	}, [refreshToken, isAuthenticated, setToken, isRefreshTokenInFlightRef, tokenRef]);
};
