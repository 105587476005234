import { MouseEventHandler, useCallback } from "react";
import * as styles from "./SomethingWentWrong.css";

export type SomethingWentWrongProps = {
	readonly error?: Error;
};

export const SomethingWentWrong = (_props: SomethingWentWrongProps) => {
	const onReloadPageLinkClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
		(event) => {
			event.preventDefault();
			location.reload();
		},
		[],
	);

	return (
		<div className={styles.container}>
			<div className={styles.innerContainer}>
				<div className={styles.errrorMessage}>Something went wrong</div>
				<div className={styles.signInLinkContainer}>
					<a
						className={styles.signInLink}
						href="/"
						onClick={onReloadPageLinkClick}
					>
						reload page
					</a>
				</div>
			</div>
		</div>
	);
};
