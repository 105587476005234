/**
 * @generated SignedSource<<00fea70453bdccbd34946b1ced284a50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignInCurrentUserFragment$data = {
  readonly __typename: "AuthError";
  readonly __typename: "AuthError";
  readonly " $fragmentType": "SignInCurrentUserFragment";
} | {
  readonly __typename: "User";
  readonly __typename: "User";
  readonly " $fragmentType": "SignInCurrentUserFragment";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "SignInCurrentUserFragment";
};
export type SignInCurrentUserFragment$key = {
  readonly " $data"?: SignInCurrentUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SignInCurrentUserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SignInCurrentUserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "AuthCurrentUserWithErrors",
  "abstractKey": "__isAuthCurrentUserWithErrors"
};

(node as any).hash = "a85f31352e8764205c2d7bd096155922";

export default node;
