import React from "react";
import useScript from "@app/hooks/useScript";

const CookiePolicy: React.FC = () => {
	useScript(
		process.env.APP_ENV === "live" ? "https://app.termly.io/embed-policy.min.js" : "",
	);

	const termlyIoCode =
		'<div name="termly-embed" data-id="54c00b55-17c6-4cce-82c4-f10286c634bc" data-type="iframe"></div>';

	return (
		<div
			className="policy-page"
			dangerouslySetInnerHTML={{ __html: termlyIoCode }}
		></div>
	);
};

export default CookiePolicy;
