import { LOCALES } from "./locales";

interface Messages {
	readonly [key: string]: {
		readonly [key: string]: string;
	};
}

export const messages: Messages = {
	[LOCALES.DUTCH]: {
		mainPage: "intro",
		winPage: "win!",
		lang1: "nederlands",
		lang2: "frans",
		mainPage_title: "<strong>WIN</strong> EEN GRATIS TUINONDERHOUD",
		mainPage_subtitle: "DOOR ONZE PROFESSIONALS",
		descriptionTitle:
			"<span class='decor'>ALTIJD EEN STAPJE</span> <br/><span class='decor'>VOOR MET EDIALUX</span>",
		descriptionSubtitle:
			"Jouw tuin straalt als nooit tevoren met de hulp van Edialux.",
		descriptionText:
			"Met deze gratis tuinonderhoudsbeurt geniet je van een tuin dat volledig klaar is voor de lente. Wil je genieten van een tuin vrij van mos, groenaanslag of onkruid? Doe dan tussen 1 maart 2022 en 15 mei 2022 mee met de Edialux wedstrijd en <strong>word één van de 10 winnaars van een gratis tuinonderhoud of maak kans op een lenteklaar tuinpakket ter waarde van €108!</strong>",

		stepsTitle: "<span class='decor'>HOE DEELNEMEN?</span>",
		step_title1: "STAP 1",
		step_description1: "<strong>Scan</strong> de QR-code en maak kans!",
		step_title2: "STAP 2",
		step_description2:
			"Vul het <strong>wedstrijdformulier</strong> in en beantwoord de <strong>2 vragen</strong>.",
		step_title3: "STAP 3",
		step_description3:
			"<strong>10 winnaars</strong> maken kans op een gratis tuinonderhoud door professionele tuinaannemers of op een lenteklaar tuinpakket.",
		stepsBtnText: "DOE MEE",
		stepsTextFinish: "DEZE WEDSTRIJD IS AFGELOPEN",

		formStep1_info: "<span class='text'>Vraag 01</span>",
		formStep1_infoText:
			"Tip: <a target='_blank' href='https://edialux.be/producten'>https://edialux.be/producten</a>",
		formStep1_Title:
			"Welk product kan je gebruiken<br class='hidden-mob'/> <span class='decor'>tegen onkruid</span> op je pad of terras?",
		formStep1_variantA: "Herbi-alarm spray",
		formStep1_variantB: "Delete spray",
		formStep1_nextStepError: "Kies een van de twee opties",

		formStep2_info: "<span class='text'>Vraag 02</span>",
		formStep2_infoText:
			"Tip: <a target='_blank' href='https://edialux.be/producten'>https://edialux.be/producten</a>",
		formStep2_Title:
			"Voor welke beestjes<br class='hidden-mob'/> gebruik je <span class='decor'>de Mirobox?</span>",
		formStep2_variantA: "Wespen",
		formStep2_variantB: "Mieren",
		formStep2_nextStepError: "Kies een van de twee opties",

		formStep3_info: "<span class='text'>Schiftingsvraag</span>",
		formStep3_infoText: "Voer een getal in dat groter is dan 0",
		formStep3_Title:
			"<span class='decor'>Hoeveel mensen</span> zullen er<br class='hidden-mob'/> deelnemen aan deze wedstrijd<br class='hidden-mob'/> tussen 1 maart 2022 en 15 mei 2022?",
		formStep3_nextStepError: "Voer een getal in dat groter is dan 0",

		formStep4_info: "<span class='text'>Vul je gegevens in</span>",
		formStep4_LabelName: "naam",
		formStep4_LabelEmail: "email",
		formStep4_LabelPhone: "gsm",
		formStep4_checkbox1:
			"Ik ga akkoord  met het <a href='/competition-rules?lang=nl' target='_blank'>wedstrijdreglement</a> en het <a href='/privacy-policy?lang=nl' target='_blank'>privacybeleid.</a><span class='sup'>*</span>",
		formStep4_checkbox2: "Ik schrijf mij in voor de nieuwsbrief.",

		formStep4_ErrorName: "Vul in Voornaam",
		formStep4_ErrorEmail: "Vul in e-mailadres",
		formStep4_ErrorPhone: "Gelieve je Telefoonnummer in te vullen",
		formStep4_ErrorReqCheckbox: "Gelieve je vink het selectievakje aan",

		formStep4_btn: "BEVESTIG & VERSTUUR",

		formSucces_text: "Bedankt voor uw deelname",
		formSucces_btn: "Naar startpagina",

		copyright_text: "© 2022 Edialux. All right reserved",
		competition_rules: "Wedstrijdreglement",
		privacy_policy: "Privacy Policy",
		cookie_policy: "Cookie Policy",
		contact:
			"<a href='https://edialux.be/contact' title='Contact' target='_blank' rel='noopener noreferrer'>Contact</a>",
		sales_conditions: "Verkoopsvoorwaarden",

		// Competition rules //
		article1: "Artikel 1 : de Organisator",
		article1_text:
			"De vennootschap Pelsis Belgium, Industrieweg 15, 2880 Bornem, België, en met ondernemingsnummer BE 0404.085.172 organiseert een wedstrijd  “WIN EEN GRATIS TUINONDERHOUD” (hieronder de “wedstrijd” genoemd).",
		article2: "Artikel 2 : Duur van de wedstrijd",
		article2_text:
			"De wedstrijd loopt van 1/03/2022 (00u01) tot 15/05/2022 (23u59) .",
		article3: "Artikel 3 : Voorwaarden",
		article3_text:
			"De wedstrijd staat open voor ieder meerderjarig persoon (op de datum van de wedstrijd) die in België woont, met uitzondering van het personeel van de Organisator, evenals elke entiteit die direct of indirect bij het ontwerp is betrokken, de organisatie, realisatie en / of het management van de wedstrijd.<br/><br/>De Organisator behoudt zich het recht voor om een ​​deelnemer van de wedstrijd uit te sluiten en / of de prijs niet toe te kennen en / of om de gehele of gedeeltelijke wedstrijd te annuleren, zonder de aansprakelijkheid van de Organisator op zich te nemen, in geval van overtreding door de deelnemer, een deelnemer van deze regels en / of als de Organisator naar eigen goeddunken van mening is dat de deelnemer heeft gehandeld of van plan is oneerlijk of bedrieglijk of ter kwader trouw te handelen.<br/><br/>In het bijzonder, maar zonder beperking, elk gebruik van robots of enig systeem, apparaat of handelingen die frauduleus, oneerlijk of ter kwader trouw zijn, of enig gedrag, waardoor bijvoorbeeld en zonder beperking, de wedstrijd automatisch wordt gespeeld en / of de kansen om te winnen worden vergroot, is verboden.De Organisator kan een klacht indienen en / of de plegers van dergelijk gedrag laten vervolgen.<br/><br/>In het geval van sancties of klachten moeten de deelnemers bewijzen dat ze gehandeld hebben in overeenstemming met dit Reglement.<br/><br/>Deelnemers kunnen op elk moment worden uitgenodigd om hun identiteit, hun woonplaats en hun leeftijd te bewijzen.<br/><br/>Deelname aan deze wedstrijd gaat niet gepaard met een aankoopverplichting.",
		article4: "Artikel 4 : Prijzen",
		article4_1: "4.1 : Omschrijving van de prijzen",
		article4_1_text:
			"Te winnen gedurende de volledige duur van de wedstrijd:<br/><br/>• 10x tuinonderhoud van 7u in een Belgische tuin door een tuinaannemer, gekozen door de Organisatie.<br/><br/>• 20x een lenteklaar tuinpakket met Edialux producten voor een Belgische tuin, gekozen door de Organisatie.",
		article4_2: "4.2 : Details van de prijzen",
		article4_2_text:
			"De waarde van de prijs wordt bepaald op de datum van voorbereiding van dit Reglement en kan niet worden betwist. De prijzen zijn niet overdraagbaar, mogen niet worden vervangen door een andere prijs en er is geen alternatief in contanten beschikbaar. De Organisator heeft het recht om de aangekondigde prijs te vervangen door een prijs met een vergelijkbare waarde en kenmerken, indien een dergelijke vervanging noodzakelijk zou blijken.<br/><br/>Elke deelnemer kan slechts één prijs winnen.",
		article5: "Artikel 5 : Bekendmaking van de winnaars en uitreiking van de prijzen",
		article5_1:
			"5.1 : Om deel te nemen aan de wedstrijd, moet de deelnemer volgende stappen nemen :",
		article5_1_text:
			"1. Elke deelnemer surft naar de wedstrijdpagina en<br/><br/>- beantwoordt de wedstrijdvragen en schiftingsvraag<br/><br/>- vult zijn/ haar voornaam, naam, e-mailadres en GSM - nummer in<br/><br/>- klikt op versturen om zijn / haar deelname te bevestigen",
		article5_2: "5.2: Bepaling van de winnaars:",
		article5_2_text:
			"Op 13/05/2022 worden er 30 winnaars geselecteerd volgens de volgende criteria:<br/><br/>• Niet uitgesloten zijn van deelneming(zie artikel 3, deelname);<br/><br/>• Ingevuld online deelnameformulier verzonden hebben voor 15 mei 2022 om middernacht met ingevulde contactgegevens.<br/><br/>• Correct antwoord op de hoofdvragen;<br/><br/>• De toekenning van de Prijs wordt verder bepaald door de schiftingsvraag.De 10 deelnemers van wie het antwoord het juiste antwoord het dichtst benadert(of die het juiste antwoord geeft) wint de Prijs.In geval van ex - aequo zal het tijdstip van de deelname doorslaggevend zijn.De volgende 20 deelnemers zullen de 2e prijs winnen.<br/><br/>De wedstrijdresultaten zullen worden bepaald en winnaars worden gecontacteerd binnen één week na het einde van de wedstrijd.<br/>De winnaars zullen worden geïnformeerd en ontvangen de prijzen via e - mail, op het e - mailadres dat online is ingevuld in het inschrijvingsformulier op de website.<br/><br/>De wedstrijd is beperkt tot één deelname per email adres.<br/><br/>Omwille van confidentialiteitsredenen zal er geen lijst met winnaars bekendgemaakt worden.<br/>",
		article6: "Artikel 6 : Reglement",
		article6_text:
			"Deelname aan de wedstrijd houdt de volledige aanvaarding van deze regels in.<br/><br/>Er zal niet geantwoord worden op verzoeken ingediend(per e- mail of telefoon) over de interpretatie of toepassing van de regels met betrekking tot de algemene voorwaarden van de wedstrijd.<br/><br/>Het reglement is beschikbaar op promo.edialux.be tijdens de duur van de wedstrijd.<br/>Dit reglement kan ook worden verkregen tot 15/05/2022 op eenvoudig schriftelijk verzoek aan: Pelsis Belgium NV – Marketing Edialux HPP – industrieweg 15, 2880 Bornem, België.De postkosten voor deze aanvraag zullen niet worden terugbetaald.<br/><br/>Voor alle elementen van de wedstrijd die niet uitdrukkelijk in dit reglement worden vernoemd, behoudt de Organisator zich het recht om alle vereiste maatregelen te nemen voor een goede organisatie van de wedstrijd.De Organisator behoudt zich het recht voor om deze regels te wijzigen wanneer zij dit nodig acht.",
		article7: "Artikel 7: Deelnamekosten",
		article7_text:
			"Deelname aan de wedstrijd is gratis, met uitzondering van de uitgaven van de deelnemer om toegang te hebben tot het internet, die door de deelnemer zelf worden gedragen.",
		article8: "Artikel 8: Beperking van aansprakelijkheid",
		article8_1_text:
			"8.1<br/><br/>De Organisator kan niet verantwoordelijk worden gehouden voor problemen die inherent zijn aan de internetverbinding, telefonische communicatie of van een ander probleem dat niet aan de Organisator zou kunnen worden toegeschreven en dat zich zou voordoen tijdens de duur van de wedstrijd.<br/>De Organisator doet er alles aan om deelnemers functionele en geverifieerde infrastructuur, informatie en hulpmiddelen te bieden en te onderhouden.De Organisator kan echter niet verantwoordelijk worden gehouden voor het falen van de apparatuur van de deelnemers(computer, software, internetverbindingshulpmiddelen, telefoon, servers ...), het verlies van gegevens daardoor die niet aan de organiserende onderneming is toe te schrijven en de impact van deze falingen op hun deelname aan de wedstrijd.<br/>Het is daarom aan elke deelnemer om alle passende maatregelen te nemen om zijn apparatuur en de gegevens die op zijn apparatuur(computer en telefoon) zijn opgeslagen te beschermen tegen inbreuken.Deelname aan de wedstrijd houdt met name de kennis en acceptatie van de kenmerken en limieten van het internet in, het gebrek aan bescherming van bepaalde gegevens tegen mogelijke verduistering of piraterij door derden en het risico van besmetting door mogelijke virussen die op internet circuleren. ",
		article8_2_text:
			"8.2<br/><br/>Deelnemers aanvaarden dat de gegevens in de informatiesystemen van de Organisator bewijskracht hebben tot het tegendeel is bewezen, met betrekking tot de verbindingselementen en informatie die het resultaat is van een computerhandeling met betrekking tot de wedstrijd.",
		article8_3_text:
			"8.3<br/><br/>De Organisator verbindt zich ertoe alle beschikbare middelen bij zijn serviceproviders te gebruiken om de goede werking van de wedstrijd te verzekeren.Mocht een technische storing zich echter voordoen en de goede werking van de wedstrijd beïnvloeden door omstandigheden buiten de controle van de Organisator, dan kan de laatste niet aansprakelijk worden gesteld door de deelnemers.",
		article8_4_text:
			"8.4<br/><br/>Als de gegevens van een winnaar onbruikbaar zijn of als de winnaar niet kan worden geïdentificeerd, is het niet de verantwoordelijkheid van de Organisator om verder onderzoek uit te voeren om de winnaar te vinden, die noch zijn prijs, noch enige compensatie of schadevergoeding zal ontvangen.",
		article8_5_text:
			"8.5<br/><br/>Winnaars worden geïnformeerd en ontvangen de prijzen via e- mail, op het e- mailadres dat online is ingevuld in het inschrijvingsformulier op de website.",
		article8_6_text:
			"8.6<br/><br/>In het geval van uitdrukkelijke afstand van de prijs door de winnaar, zal deze prijs teruggegeven worden aan de Organisator en kan deze worden gebruikt voor een volgende actie, als de aard van de prijs dit toelaat en zonder enige aansprakelijkheid vanwege de Organisator. ",
		article8_7_text:
			"8.7 <br/><br/>Deze wedstrijd wordt op geen enkele manier gesponsord, goedgekeurd of beheerd door of in verband gebracht met Facebook/ Twitter / Instagram of andere social media kanalen.Door zijn deelname aan de wedstrijd stemt elke deelnemer ermee in om Facebook/ Twitter / Instagram en al hun respectieve kaderleden, directeurs, personeelsleden, vertegenwoordigers en agenten te ontslaan van elke aansprakelijkheid, schade, verlies of verwonding die volledig of gedeeltelijk, rechtstreeks of onrechtstreeks het gevolg is van de deelname van de deelnemer aan de wedstrijd en de aanvaarding, het gebruik of het misbruik van elke prijs die hij eventueel wint.",
		article8_8_text:
			"8.8<br/><br/>De wedstrijd kan mogelijk worden beïnvloed door verplichte inspanningen om de verspreiding van COVID19 tegen te gaan.De Organisator behoudt zich daarom het recht voor om<br/><br/>• de wedstrijd gedeeltelijk of volledig uit te stellen / te annuleren<br/><br/>• vervanging of annulering van een bepaalde prijs indien de Organisatie dit nodig acht vanwege beperkingen opgelegd door lokale autoriteiten of een vereiste met betrekking tot COVID19.<br/><br/>Winnaars moeten mogelijk een COVID 19 - test afleggen, indien nodig.Winnaars moeten voldoen aan alle maatregelen met betrekking tot COVID19.",
		article9: "Artikel 9: Contactname",
		article9_text:
			"Er wordt geen rekening gehouden met niet-conforme correspondentie (onvolledig, onleesbaar, onjuist, niet-gefrankeerd, uitgesteld of niet verzonden).",
		article10: "Artikel 10: Diskwalificatie",
		article10_text:
			"Deelnemers staan alle verificaties met betrekking tot hun identiteit, leeftijd, adres en telefoonnummer als onderdeel van de Wedstrijd toe. Elke deelname die niet voldoet aan deze voorschriften, onvolledig of met onjuiste coördinaten zal als ongeldig worden beschouwd.<br/><br/>Het gebruik van robots of een andere soortgelijke methode om de wedstrijd mechanisch of anderszins te spelen is verboden, de overtreding van deze regel resulteert in de uiteindelijke eliminatie van de deelnemer en / of gebruiker.De Organisator kan de wedstrijd geheel of gedeeltelijk annuleren als blijkt dat fraude heeft plaatsgevonden in welke vorm dan ook, via de computer of het feit van deelname onder de identiteit van een derde partij en / of de bepaling van de winnaars.In dit geval behoudt de Organisator zich het recht voor om geen prijzen toe te kennen aan fraudeurs en / of om de daders van deze fraude voor de bevoegde rechtbanken te brengen.",
		article11: "Artikel 11: Overmacht",
		article11_text:
			"De Organisator is niet aansprakelijk in geval van overmacht of gebeurtenissen waarover hij geen controle heeft of indien de omstandigheden dit vereisen, hij verplicht is de huidige wedstrijd te annuleren, in te korten, te verlengen, uitstellen, op te schorten of de voorwaarden te wijzigen.<br/><br/>In ieder geval behoudt de Organisator zich het recht voor om de deelnameperiode te verlengen.",
		article12: "Artikel 12: Vrijstelling van aansprakelijkheid",
		article12_text:
			"De winnaars zijn volledig verantwoordelijk voor het gebruik van hun prijs. De Organisator kan niet aansprakelijk worden gesteld voor defecten of problemen met de prijzen of voor om het even welk incident of ongeval dat de winnaars zou kunnen overkomen tijdens of wegens het gebruik of het bezit van de prijs.",
		article13:
			"Artikel 13: Machtiging om de namen en adressen van de winnaars te gebruiken",
		article13_text:
			"Op voorwaarde van het verkrijgen van de uitdrukkelijke toestemming van de hoofddeelnemer, kan de Organisator de naam en het e-mailadres van de winnaars gebruiken voor reclamedoeleinden zonder hen recht te geven op een vergoeding of een ander voordeel over te dragen.",
		article14: "Artikel 14: Intellectuele eigendomsrechten en auteursrechten",
		article14_text:
			"In overeenstemming met de wetten met betrekking tot intellectuele eigendomsrechten en auteursrechten, is het gebruik van alle of een deel van het auteursrechtelijk beschermde materiaal van deze wedstrijd ten strengste verboden, behalve met de voorafgaande uitdrukkelijke toestemming van de Organisator. ",
		article15: "Artikel 15: Toepasselijk recht en geschillen",
		article15_text:
			"De wedstrijd is onderworpen aan de Belgische wetgeving (België).<br/><br/>Elk geschil met betrekking tot de interpretatie van de regels en / of gevallen waarin dit reglement niet voorziet, zal het voorwerp uitmaken van een minnelijke schikking.Anders is de exclusieve bevoegdheid van de rechtbanken van Antwerpen(België).",
		article16: "Artikel 16: Bescherming van persoonsgegevens",
		article16_text:
			"De Organisator handelt in overeenstemming met de toepasselijke regelgeving op de gegevensbescherming.<br/>Wij gebruiken de persoonsgegevens die wij over u verzamelen om u in staat te stellen om aan de wedstrijd deel te nemen en om contact met u op te nemen als u een prijs hebt gewonnen.De informatie die wij over u verzamelen, wordt enkel verzameld met als doel de winnaar(s) te kunnen identificeren.Deze gegevens zullen bewaard worden voor de duur van de wedstrijd(termijn van de wedstrijd zoals gedefinieerd onder artikel 2 + periode waarbinnen de winnaar(s) en de prijzen toegewezen worden.Meer informatie over hoe wij met persoonsgegevens omgaan, is te vinden in onze Privacyverklaring. ",

		// Privacy policy //
		privacy_title1: "About this Privacy Notice",
		privacy_text1:
			"Pelsis are committed to being transparent and protecting your data; therefore, this notice includes how we store and protect your data, who we share your data with and how long we hold your data for. This notice also outlines your rights and the actions you should take if you are concerned with the way Pelsis is handling your personal data. Should we ask you to provide certain information by which you can be identified, you can be assured that it will only be used in accordance with this privacy notice.",
		privacy_title2: "Who we are",
		privacy_text2:
			"Pelsis Limited, with its headquarters at Sterling House, Grimbald Crag Close, Knaresborough, HG5 8PJ, is the ‘data controller’ under the General Data Protection Regulations (referred to as GDPR throughout this document) and is responsible for the processing of client data.<br/><br/>Should you have any queries or questions regarding anything in this document or anything further please contact <a href='mailto:GDPR@pelsis.com;' target='_blank'>GDPR@pelsis.com</a>",
		privacy_title3: "What type of personal data may be collected by Pelsis",
		privacy_text3:
			"• Company name and address<br/>• Contact name<br/>• Email address, website and telephone number<br/>• VAT number<br/>• Payment details – card details and/ or bank details<br/>• Training certificates and photo IDs",
		privacy_title4: "How Pelsis collect information about you",
		privacy_text4:
			"• When you place a sales order with our sales team<br/>• When you request an online account<br/>• When you make an enquiry with any of our internal departments<br/>• When you submit an enquiry though our online enquiry form<br.• When making a booking for the use of The Pelsis Academy<br/>• When we receive your photo ID and training certificates",
		privacy_title5:
			"How Pelsis use your personal information and the lawful basis for doing so",
		privacy_text5:
			"Pelsis will only use your personal data in the following circumstances:<br/><br/>• Where we need to perform the contract we are about to enter into or have entered into with you<br/>• Where it is necessary for our legitimate interests and your interests and fundamental rights do not override those interests<br/>• Where we need to comply with a legal or regulatory obligation<br/><br/>The data may be used for the following purposes:<br/><br/>• To supply the products and/ or services requested by you<br/>• To manage your account within the Pelsis group<br/>• To enable us to contact you<br/>• To enable us to notify you of any changes to products, services or prices<br/>• To carry out market research and product analysis",
		privacy_title6: "Marketing",
		privacy_text6:
			"If you have given your explicit consent, we may use your information to contact you by post, telephone, email or text for:<br/><br/>• Your views on current services that we provide<br/>• Notifying you of important changes or developments to our business<br/>• Letting you know about products or services that may be of interest of you<br/>• Outlining promotional activity<br/>• Inviting you to events that we may be holding<br/><br/>If you have provided us with a business card, we will add you to our marketing list.<br/><br/>If you have not given us your consent then we will not contact you regarding anything other than your account with Pelsis.<br/><br/>You may withdraw your consent at any time by clicking on the ‘unsubscribe’ link at the bottom of the marketing emails or by contacting <a href='mailto:market@pelsis.com' target='_blank'>market@pelsis.com</a>",
		privacy_title7: "How Pelsis share your personal data",
		privacy_text7:
			"Pelsis do have to share your data with a restricted, trusted number of third party companies who we ensure are GDPR compliant.<br/><br/>Companies we may share information with are:<br/><br/>• With MailChimp*, printing and mail distributors in order to send you marketing communications<br/>• Transport companies so that they can deliver your goods quickly and efficiently<br/>• Suppliers in case we deliver goods directly to you<br/>• Company solicitors if we ever need to recover an outstanding debt<br/>• Credit checking companies if you have consented, completed and signed a credit application form<br/>• Training providers such as RSPH, if your personal details are requested to register you on courses and send certificates upon completion<br/><br/>*MailChimp’s servers are located in the United States so information may be transferred to, stored, or processed in the United States.However, MailChimp participates in and has certified its compliance with the EU- US Privacy Shield Framework and the Swiss- U.S.Privacy Shield Framework.<br/><br/>Pelsis may transfer your personal data outside the European Economic Area (EEA) and your data may be transferred to the UK from outside the EEA.If this happens, we will make sure that suitable safeguards are in place and that your personal data is protected at the same level that it is in the UK.",
		privacy_title8: "How long does Pelsis keep your data for",
		privacy_text8:
			"The above personal data will stay on file for as long as we have reasonable business needs and we will retain the personal data in line with legal and regulatory requirements or guidance.<br/><br/>Your online account information will be kept for as long as your account is active or as needed to provide services.If you no longer wish to have an active online account please request your account to be closed and your data will be deleted.<br/><br/>You may request for your personal data to be removed from Pelsis and its services by contacting <a href='mailto:market@pelsis.com' target='_blank'>market@pelsis.com</a>",
		privacy_title9: "Storage, security and transfers",
		privacy_text9:
			"Pelsis take the security and protection of your personal data very seriously. We have industry standard security measures to ensure that your personal data is secure against both external and internal threats with access to personal data restricted to those who need to process it.<br/><br/>Once your personal data has been processed, it will be securely stored on our company servers which have restricted access.<br/><br/>Your name and personal business email address will be stored on our third- party Customer Relationship Management(CRM) system which has access restricted to only those who have a business need to see this information.Our CRM is SOC 2 compliant and maintains reasonable security measures to protect your information from loss, destruction, misuse, unauthorised access or disclosure.Our CRM complies with the EU- U.S.Privacy Shield Framework and the Swiss – U.S.Privacy Shield Framework as set forth by the U.S.<br/><br/>You will need to ensure that your online account is protected against unauthorised access and choose a password that is not easily accessible.You must also make sure you sign out after using the services.",
		privacy_title10: "Links to other websites",
		privacy_text10:
			"Our website may contain links to other websites of interest. This privacy notice only applies to this website so when you link to other websites you should read their own privacy policies.",
		privacy_title11: "How we use cookies",
		privacy_text11:
			"Cookies are text files placed on your computer to collect standard internet log information and visitor behaviour information. This information is used to track visitor use of the website and to compile statistical reports on website activity.<br/><br/>For further information visit <a href='http://www.aboutcookies.org/' target='_blank'>www.aboutcookies.org</a> or <a href='http://www.allaboutcookies.org/' target='_blank'>www.allaboutcookies.org</a><br/><br/>You can set your browser not to accept cookies and the above websites tell you how to remove cookies from your browsers.However, in a few cases some of our website features may not function as a result.<br/><br/>On sites that have the Twitter feed enabled, Twitter may set cookies.For more details on what information Twitter collects, please visit their website <a href='https://twitter.com/en/privacy' target='_blank'>https://twitter.com/en/privacy</a><br/><br/>We may also embed videos from YouTube onto our websites.YouTube uses cookies to help prevent fraud and improve site experience.If you view a video, YouTube may set cookies on your computer.Please visit the YouTube page on embedding videos for more information:<br/><br/><a href='http://support.google.com/youtube/bin/answer.py?hl=en-GB&answer=171780&expand=PrivacyEnhancedMode#privacy' target='_blank'>http://support.google.com/youtube/bin/answer.py?hl=en-GB&answer=171780&expand=PrivacyEnhancedMode#privacy</a>",
		privacy_title12: "Access to your information and correction",
		privacy_text12:
			"Under the new regulations you have the right to access a copy to your own personal data that we hold. This is called a Subject Access Request (SAR) and any requests must be made in writing and sent to the Data Protection Officer, Sterling House, Grimbald Crag Close, Knaresborough, HG5 8PJ or emailed to <a href='mailto:GDPR@pelsis.com' target='_blank'>GDPR@pelsis.com</a><br/><br/>There is normally no charge for a SAR, however if the request is unfounded or excessive, Pelsis reserve the right to apply a charge.<br/><br/>Full information will be provided within one month of receipt.If the request is manifestly complex, Pelsis can extend this time period by a maximum of two months.If this is the case, you will be informed and kept up to date with the progress throughout.<br/><br/>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible.We will promptly correct any information found to be incorrect.",
		privacy_title13: "Right to complain",
		privacy_text13:
			"We hope that you never have a reason to complain and would ask that you speak to one of our team so that we can resolve any issue before you need make a complaint. However, should you feel that the adequate action is not being taken, you have the right to inform a supervisory authority, such as the Information Commissioner’s Office (ICO) <a href=tel:+4403031231113'>(+44) 0303 123 1113</a>, <a href='https://ico.org.uk/' target='_blank'>https://ico.org.uk/</a>",
		privacy_title14: "Changes to our privacy notice",
		privacy_text14:
			"Any changes we may make to our privacy notice in the future will be posted on this page and where appropriate, we will notify you by email.",

		// Sales conditions //
		conditions_title: "ALGEMENE VERKOOPS- EN LEVERINGSVOORWAARDEN",
		conditions_about:
			"Alleen onze hierna vermelde algemene voorwaarden zijn van toepassing, niettegenstaande alle bijzondere of algemene voorwaarden vermeld op aankooporders of andere documenten van de koper of opdrachtgever.",
		conditions_title1: "Artikel 1. Offertes-Orderbevestigingen-Prijzen-Bewijs.",
		conditions_text1:
			"Onze offertes en orderbevestigingen zijn slechts 5 dagen geldig tenzij wij ervoor opteren om deze alsnog uit te voeren. Onze prijzen zijn vrijblijvend, exclusief BTW en exclusief vervoerkosten, tenzij expliciet anders vermeld. De koper aanvaardt dat onze electronische bestanden eveneens gelden als bewijs van zijn contractuele verplichtingen.",
		conditions_title2: "Artikel 2. Leveringstermijn.",
		conditions_text2:
			"Leveringstermijnen zijn steeds niet􀀬blndend en worden slechts als aanwijzing gegeven, zodanig dat een eventuele vertraging aan de koper in geen geval recht geeft op schadevergoeding of ontbinding van de koop.",
		conditions_title3: "Artikel 3. Leveringsplaats-Risico.",
		conditions_text3:
			"Alle leveringen worden gedaan in de exploitatiezetel te Bornem. De overhandiging van de goederen geschiedt op geldige wijze door de terbeschikkingstelling van het verkochte materiaal aan de koper in onze lokalen zodra de goederen geïndividualiseerd zijn. De koper draagt in ieder geval het risico van de goederen vanaf deze terbeschikkingstelling, zelfs ingeval van franco verkoop of van vervoer door de verkoper.",
		conditions_title4: "Artikel 4. Verpakkingen.",
		conditions_text4:
			"In voorkomend geval kunnen wij verpakkingskosten in rekening brengen. Verpakkingen worden niet teruggenomen tenzij expliciet anders vermeld.",
		conditions_title5: "Artikel 5. Klachten.",
		conditions_text5:
			"Eventuele klachten betreffende de hoeveelheid, aard, hoedanigheid en conformiteit van de goederen dienen, op straffe van verval, op het ogenblik van de aanvaarding te geschieden en alleszins uiterlijks dagen na ontvangst per aangetekend schrijven aan ons te worden bevestigd. Klachten betreffende verborgen gebreken dienen, op straffe van verval, uiterlijk a dagen na de ontdekking en ten laatste 6 maanden na levering per aangetekend schrijven aan ons te worden bevestigd. In geen geval kan een klacht ertoe leiden dat de betaling van de prijs geheel of gedeeltelijk wordt opgeschort.",
		conditions_title6: "Artikel 6. Aansprakelijkheid en garantie.",
		conditions_text6:
			"Onze aansprakelijkheid is alleszins dubbel beperkt, enerzijds tot maximum de vervanging van de geleverde goederen met uitsluiting van alle andere vergoedingen voor rechtstreekse of onrechtstreekse schade, zoals onder meer schade aan personen, goederen en gewassen van de koper of derden en, anderzijds tot de bedragen die door onze verzekeraars worden uitbetaald en waarvoor dekking wordt verleend. Deze dubbele aansprakelijkheidsbeperking geldt voor het totaal van hetgeen de klant en eventuele derden te vorderen zouden hebben. Wij wijzen er uitdrukkelijk op dat geen enkele aansprakelijkheid voor efficiëntie van behandelingen of voor (gevolg-) schade wordt aanvaard gezien wij geen controle hebben over omgevingsfactoren (inzonderheid toegepaste concentratie en hoeveelheden, klimatologische, milieu­en omgevingsfactoren, aard van de behandelde goederen, ... ). Wat betreft onze toestellen verlenen wij een waarborg van, jaar vanaf datum van verzending naar de koper. Deze garantie betreft onderdelen en werkuren en geldt enkel bij normaal gebruik. Lampen, dichtingen en defecten ingevolge normale slijtage vallen buiten de garantie. De herstellingen onder garantie gebeuren na aflevering van het toestel in onze exploitatiezetel. Transportkosten zijn ten laste van de koper.",
		conditions_title7: "Artikel 7. Betaling.",
		conditions_text7:
			"Behoudens andersluidende en schriftelijke bepaling zijn onze facturen contant betaalbaar op onze exploitatiezetel te Bornem. Eventuele wisselrisico's zijn uitsluitend ten laste van de koper die ons hiervoor zal dienen te vergoeden en te vrijwaren. Behoudens andersluidende schriftelijke bepaling hebben onze vertegenwoordigers of agenten geen toelating om gelijk welke sommen in onze naam. in ontvangst te nemen.",
		conditions_title8: "Artikel 8. Wanbetaling.",
		conditions_text8:
			"Voor bedragen die door de koper op de vervaldag niet zijn betaald, is van rechtswege en zonder ingebrekestelling een nalatigheidsintrest van , o/o per maand verschuldigd waarbij elke begonnen maand als een volledige maand wordt beschouwd. Bovendien is de koper bij laattijdige betaling een schadevergoeding verschuldigd die forfaitair wordt bepaald op 10 o/o van het factuurbedrag met een minimum van € 100,00. Wij behouden ons tevens het recht voor om de werkelijk geleden schade te bewijzen. De niet-betaling op de vervaldag van één factuur maakt alle andere facturen, zelfs niet­vervallen, onmiddellijk opeisbaar.",
		conditions_title9: "Artikel 9. Schuldhernieuwing.",
		conditions_text9:
			"Het trekken, aanvaarden of in omloop brengen van wissels of andere verhandelbare documenten brengt geen schuldvernieuwing teweeg en houdt geen afwijking in op deze voorwaarden.",
		conditions_title10: "Artikel 10. Schuldvergelijking.",
		conditions_text10:
			"Behoudens andersluidende en schriftelijke bepaling vormen alle vorderingen met dezelfde klant, ongeacht hun rechtskarakter of de eraan verbonden voorwaarden, onderdeel van één overeenkomst waarvan de wederzijdse verplichtingen elkaar voortdurend compenseren.",
		conditions_title11: "Artikel 11. Verbintenissen.",
		conditions_text11:
			"Eventuele verbintenissen aangegaan door onze vertegenwoordigers of agenten buiten het kader van deze voorwaarden binden ons slechts na uitdrukkelijke schriftelijke aanvaarding door onze directie.",
		conditions_title12: "Artikel 12. Wanprestatie.",
		conditions_text12:
			"Indien de koper nalaat zijn verbintenissen uit te voeren, zal de koop lastens de koper van rechtswege en zonder ingebrekestelling ontbonden / verbroken zijn. De schadevergoeding wegens wanprestatie van de koper wordt forfaitair vastgesteld op 15 o/o van de koopprijs met een minimum van€ 500,00. Wij behouden ons tevens het recht voor om de uitvoering in natura te vervolgen en/ of de werkelijke schade te bewijzen.",
		conditions_title13: "Artikel 13. Kredietwaardigheid.",
		conditions_text13:
			"Indien ons vertrouwen in de kredietwaardigheid van de koper geschokt wordt, ondermeer door daden van gerechtelijke uitvoering tegen de koper of andere aanwijsbare redenen, behouden wij ons het recht voor, zelfs indien de goederen reeds geheel of gedeeltelijk werden verzonden, de gehele bestelling of een gedeelte ervan op te schorten totdat de koper is overgegaan tot het stellen van de nodige waarborgen voor de goede uitvoering van zijn verplichtingen. Indien de koper weigert om hierop in te gaan, zal dit een wanprestatie zijn in zijnen hoofde in de zin van artikel 12 en zal dit artikel integraal van toepassing zijn.",
		conditions_title14: "Artikel 14. Eigendomsvoorbehoud.",
		conditions_text14:
			"De goederen blijven onze eigendom tot volledige betaling van zowel de hoofdsom, het schadebeding, de intresten en de kosten. Alle risico's zijn ten laste van de koper. De betaalde voorschotten blijven ons verworven als schadevergoeding onder meer voor de eventuele verliezen bij wederverkoop. Ingeval de koper de ons toebehorende goederen verkoopt, zelfs verwerkt, draagt hij alle schuldvorderingen die voortkomen uit deze verkoop aan ons over. Hij verbindt er zich toe de wederpartij hiervan in te lichten.",
		conditions_title15: "Artikel 15. Bevoegdheid-Toepasselijk recht.",
		conditions_text15:
			"Ingeval van betwisting, zijn enkel de rechtbanken van onze maatschappelijke zetel, deze van onze exploitatiezetel of deze van de woonplaats / maatschappelijke zetel van de koper, naar onze keuze, bevoegd. Alleszins zijn de Belgische rechtbanken bevoegd en is enkel Belgisch recht van toepassing.",
		conditions_title16: "Artikel 16. Verzaking-Nietigheid-Voorrang.",
		conditions_text16:
			"Het niet-toepassen van één of meer bepalingen van onderhavige voorwaarden kan door de koper nooit als verzaking aan deze voorwaarden worden aanzien. De nietigheid van een of meer artikelen of een gedeelte van een artikel tast de geldigheid van deze voorwaarden niet aan. Onze voorwaarden hebben in ieder geval voorrang op deze van de koper die, door het plaatsen van een bestelling, afstand doet van zijn eigen algemene voorwaarden en hierop geen aanspraak meer zal maken.",
	},
	[LOCALES.FRENCH]: {
		mainPage: "intro",
		winPage: "GAGNEZ !",
		lang1: "Néerlandais",
		lang2: "français",
		mainPage_title: "<strong>Gagnez</strong> un entretien gratuit de votre jardin",
		mainPage_subtitle: "par nos professionnels",
		descriptionTitle:
			"<span class='decor'>toujours un temps</span> <br/><span class='decor'>d’avance avec</span> </br><span class='decor'>Edialux</span>",
		descriptionSubtitle: "Votre jardin plus beau que jamais avec l’aide d’Edialux.",
		descriptionText:
			"Avec cet entretien de jardin gratuit, vous pouvez faire nettoyer tout votre jardin pour le printemps. Vous voulez profiter d’un jardin sans mousse, sans dépôts verts ou mauvaises herbes ? Alors participez au concours Edialux entre le 1er mars 2022 et le 15 mai 2022 et <strong>gagnez l’un des 10 entretiens de jardin gratuit, ou un pack de jardinage de printemps d’une valeur de 108 €.</strong>",
		stepsTitle: "<span class='decor'>Comment participer?</span>",
		step_title1: "Étape 1",
		step_description1: "<strong>Scannez</strong> le code QR et tentez votre chance !",
		step_title2: "Étape 2",
		step_description2:
			"<strong>Remplissez</strong> le formulaire du concours et répondez aux <strong>2 questions.</strong>",
		step_title3: "Étape 3",
		step_description3:
			"<strong>10 gagnants</strong> remporteront un entretien de jardin gratuit par nos professionnels, ou un pack de jardinage de printemps.",
		stepsBtnText: "Participez",
		stepsTextFinish: "CE CONCOURS EST TERMINÉ",

		formStep1_info: "<span class='text'>Question 01</span>",
		formStep1_infoText:
			"Indice: <a target='_blank' href='https://edialux.be/fr/produits'>https://edialux.be/fr/produits</a>",
		formStep1_Title:
			"Quel produit pouvez-vous utiliser <span class='decor'>contre les mauvaises herbes</span> sur votre<br class='hidden-mob'/> sentier ou votre terrasse ?",
		formStep1_variantA: "Herbi-alarm spray",
		formStep1_variantB: "Delete spray",
		formStep1_nextStepError: "Choisissez l'une des deux options",

		formStep2_info: "<span class='text'>Question 02</span>",
		formStep2_infoText:
			"Indice: <a target='_blank' href='https://edialux.be/fr/produits'>https://edialux.be/fr/produits</a>",
		formStep2_Title:
			"Contre quels animaux<br class='hidden-mob'/> utilisez-vous <span class='decor'>la Mirobox</span> ?",
		formStep2_variantA: "Les guêpes",
		formStep2_variantB: "Les fourmis",
		formStep2_nextStepError: "Choisissez l'une des deux options",

		formStep3_info: "<span class='text'>Question subsidiaire</span>",
		formStep3_infoText: "Veuillez entrer un nombre supérieur à 0",
		formStep3_Title:
			"<span class='decor'>Combien de personnes</span> vont<br class='hidden-mob'/> participer à ce concours entre<br class='hidden-mob'/> le 1er mars 2022 et le 15 mai 2022 ?",
		formStep3_nextStepError: "Veuillez entrer un nombre supérieur à 0",

		formStep4_info: "<span class='text'>Complétez vos coordonnées</span>",
		formStep4_LabelName: "Nom",
		formStep4_LabelEmail: "email",
		formStep4_LabelPhone: "gsm",
		formStep4_checkbox1:
			"J’accepte <a href='/competition-rules?lang=fr' target='_blank'>le règlement du concours</a> et <a href='/privacy-policy?lang=fr' target='_blank'>la politique de confidentialité.</a> <span class='sup'>*</span>",
		formStep4_checkbox2: "Je m’inscris à la newsletter.",

		formStep4_ErrorName: "Entrer Nom",
		formStep4_ErrorEmail: "Entrer e-mail",
		formStep4_ErrorPhone: "Veuillez entrer votre numéro de téléphone",
		formStep4_ErrorReqCheckbox: "Veuillez accepter notre Notice Vie Privée",

		formStep4_btn: "Confirmez & envoyez",

		formSucces_text: "Merci pour votre participation",
		formSucces_btn: "À la page d'accueil",

		copyright_text: "© 2022 Edialux. All right reserved",
		competition_rules: "Règlement du concours",
		privacy_policy: "Privacy Policy",
		cookie_policy: "Cookie Policy",
		contact:
			"<a href='https://edialux.be/fr/contact' title='Contact' target='_blank' rel='noopener noreferrer'>Contact</a>",
		sales_conditions: "Conditions de vente",

		// Competition rules //
		article1: "Article 1: l'Organisateur",
		article1_text:
			"La société Pelsis Belgium, Industrieweg 15, 2880 Bornem, Belgique, enregistrée sous le numéro d'entreprise BE 0821.675.122, organise un concours «GAGNEZ UN ENTRETIEN GRATUIT DE VOTRE JARDIN» (ci-après le « concours »).",
		article2: "Article 2: Durée du concours",
		article2_text: "Le concours se déroule 1/03/2022 (00h01) au 15/05/2022 (23h59).",
		article3: "Article 3: Conditions",
		article3_text:
			"Le concours est ouvert à toute personne adulte (à la date du concours) qui vit en Belgique et au Luxembourg, à l'exception des employés de la Société Organisatrice, ainsi que toute personne ayant participé directement ou indirectement à l’élaboration, l'organisation, la mise en œuvre et / ou la gestion du concours.<br/><br/>L'organisateur se réserve le droit d'exclure un participant du concours et / ou de ne pas attribuer le prix et / ou d'annuler tout ou partie du concours, sans engager la responsabilité de l'organisateur, en cas de violation par un participant du présent Règlement et / ou si la Société Organisatrice, à sa seule discrétion, pense que le participant a agi ou a l’intention d’agir de manière malhonnête ou frauduleuse, ou de mauvaise foi.<br/><br/>En particulier, mais sans limitation, toute utilisation de robots ou tout système, appareil ou manœuvres frauduleux, malhonnêtes ou de mauvaise foi, ou toute conduite, permettant par exemple et sans limitation, de jouer automatiquement et / ou d’augmenter les chances de gagner, est interdit.La Société Organisatrice peut déposer une plainte et / ou porter plainte contre les auteurs de telles conduites.<br/><br/>En cas de sanction ou de plainte, les participants devront prouver qu’ils ont adopté une conduite qui est conforme au présent Règlement.<br/><br/>Les participants peuvent être invités à n’importe quel moment à apporter la preuve de leur identité, résidence, et âge.<br/><br/>La participation à cette compétition n’est pas accompagnée d'une obligation d'achat.",
		article4: "Article 4: Prix",
		article4_1: "4.1: Description des prix",
		article4_1_text:
			"Les prix à gagner pour l'ensemble du concours sont :<br/><br/>• 10x l'entretien du jardin de 7 heures dans un jardin belge par un entrepreneur de jardinage, choisi par l'Organisation.<br/><br/>• 20x un forfait jardin de printemps avec des produits Edialux pour un jardin belge, choisis par l'Organisation.",
		article4_2: "4.2: Détails des prix",
		article4_2_text:
			"La valeur des prix est déterminée à la date de préparation du présent règlement et ne peut être contestée. Les prix ne sont pas transférables, ne peuvent pas être remplacés par un prix différent et il n'y a pas d'alternative possible en espèces. L'Organisateur a le droit de remplacer le prix annoncé par un prix ayant une valeur et des caractéristiques similaires, si un tel remplacement est nécessaire.<br/><br/>Chaque participant ne peut gagner qu'un seul prix.",
		article5: "Article 5: Détermination des gagnants et remise des prix",
		article5_1:
			"5.1: Pour participer au concours, le participant doit prendre les mesures suivantes:",
		article5_1_text:
			"1. chaque participant se rend sur la page concours et<br/><br/>- répond à les questions et la question subsidiaire<br/><br/>- remplit son prénom, nom, adresse e- mail et numéro de téléphone<br/><br/>- clique sur envoyer pour confirmer sa participation",
		article5_2: "5.2: Sélection des gagnants:",
		article5_2_text:
			"Le 13/05/2022, 30 gagnants seront sélectionnés selon les critères suivants :<br/><br/>• Ils ne sont pas exclus de la participation(article 3, conditions)<br/><br/>• Ils ont rempli et envoyé le formulaire de participation avant le 15 / 05 / 2022 soir 23h59 avec leurs cordonnés.<br/><br/>• Les réponses correctes aux questions principales<br/><br/>• Les gagnants sont sélectionnés parmi ceux qui auront répondu correctement à la question du concours et qui auront estimé au mieux la réponse à la question subsidiaire, où la plus petite différence avec la réponse correcte est décisive.Si on constate un ex - aequo, c’est le gagnant qui aura répondu correctement et ayant donné la meilleure estimation en premier qui remporte le prix.Les 20 participants suivants remporteront le 2e prix.<br/><br/>Les résultats du concours seront déterminés et les gagnants seront contactés dans un délai d'une semaine après la fin du concours.<br/><br/>Les gagnants seront informés et recevront leur prix par e - mail à l'adresse e-mail indiquée en ligne dans le formulaire d'inscription sur le site.<br/><br/>Le concours est limité à une participation par adresse électronique.<br/><br/>Pour des raisons de confidentialité, aucune liste de gagnants ne sera publiée.",
		article6: "Article 6: Règlement",
		article6_text:
			"La participation au concours implique l'acceptation pleine et entière de ces règles.<br/><br/>Aucune réponse ne sera envoyée(par e- mail ou par téléphone) concernant l'interprétation ou l'application des règles relatives aux conditions générales du concours.<br/><br/>Le règlement est disponibles sur promo.edialux.be pendant la durée du concours.<br/><br/>Ce règlement peut également être obtenu jusqu'au 15/05/2022 sur simple demande écrite à : Pelsis Belgium NV – Marketing Edialux HPP – industrieweg 15, 2880 Bornem, Belgique. Les frais postaux pour cette application ne seront pas remboursés.<br/><br/>Pour tous les éléments du concours qui ne sont pas explicitement mentionnés dans ce règlement, l'organisateur se réserve le droit de prendre toutes les mesures nécessaires pour une bonne organisation du concours. L'organisateur se réserve le droit de modifier ces règles si nécessaire.",
		article7: "Article 7: Frais de participation",
		article7_text:
			"La participation au concours est gratuite, à l'exception des frais du participant pour accéder à Internet, qui sont payés par le participant lui-même.",
		article8: "Article 8: Limitation de responsabilité",
		article8_1_text:
			"8.1<br/><br/>L'Organisateur ne peut être tenu responsable des problèmes inhérents à la connexion Internet, à la communication téléphonique ou à tout autre problème qui ne pourrait être imputé à l'Organisateur et qui se produirait pendant la durée du concours.<br/>L'organisateur met tout en œuvre pour fournir et maintenir des infrastructures, des informations et des ressources fonctionnelles et vérifiées pour les participants. Toutefois, l'Organisateur ne peut être tenu responsable de la défaillance de l'équipement des participants (ordinateur, logiciel, outils de connexion Internet, téléphone, serveurs ...), de la perte de données non imputable à la société organisatrice et de l'impact de ces échecs sur leur participation au concours.<br/><br/>Il appartient donc à chaque participant de prendre toutes les mesures appropriées pour protéger son équipement et les données stockées sur son équipement(ordinateur et téléphone) contre les infractions.La participation au concours comprend notamment la connaissance et l'acceptation des caractéristiques et des limites de l'internet, l'absence de protection de certaines données contre une éventuelle éclipse ou piratage par des tiers et le risque de contamination par d'éventuels virus circulant sur internet.",
		article8_2_text:
			"8.2<br/><br/>Les participants acceptent que les données contenues dans les systèmes d'information de l'Organisateur aient une valeur probante jusqu'à preuve du contraire, en ce qui concerne les éléments de connexion et les informations résultant d'une action informatique par rapport au concours.",
		article8_3_text:
			"8.3<br/><br/>L'Organisateur s'engage à utiliser tous les moyens disponibles auprès de ses prestataires pour assurer le bon fonctionnement du concours. Toutefois, si un dysfonctionnement technique se produit et que le bon fonctionnement du concours est affecté par des circonstances indépendantes de la volonté de l'organisateur, ce dernier ne peut être tenu responsable par les participants.",
		article8_4_text:
			"8.4<br/><br/>Si les données d'un gagnant sont inutilisables ou si le gagnant ne peut pas être identifié, il n'est pas de la responsabilité de l'organisateur de mener d'autres recherches pour trouver le gagnant, qui ne recevra ni son prix ni aucune compensation.",
		article8_5_text:
			"8.5<br/><br/>Les gagnants seront contactés et recevront leur prix par e-mail, à l'adresse e-mail indiquée sur le formulaire d'inscription.",
		article8_6_text:
			"8.6<br/><br/>Dans le cas d'une renonciation explicite du prix par le gagnant, ce prix sera retourné à l'organisateur et pourra être utilisé pour une promotion ultérieure, si la nature du prix le permet et sans aucune responsabilité de la part de l'organisateur.",
		article8_7_text:
			"8.7 <br/><br/>Ce concours n'est en aucun cas sponsorisé, approuvé ou géré par ou associé à Facebook / Twitter / Instagram ou d’autres media sociaux. En participant au concours, chaque participant accepte de libérer Facebook / Twitter / Instagram et tous leurs cadres, directeurs, employés, représentants et agents respectifs de toute responsabilité, dommage, perte ou blessure, qui serait en tout ou en partie, directement ou indirectement, une conséquence de la participation du participant au concours et de l'acceptation, de l'utilisation ou de la mauvaise utilisation de tout prix qu'il gagnerait éventuellement.",
		article8_8_text:
			"8.8<br/><br/>Ce concours pourrait éventuellement être affectée par les efforts mandatés pour freiner la propagation de la COVID19. La Société Organisatrice se réserve donc le droit de<br/><br/>- reporter / annuler des parties ou la totalité de l’activation<br/>- remplacer ou annuler un certain prix si la Société Organisatrice le juge nécessaire en raison de restrictions imposées par les autorités locales ou de toute exigence liée à la COVID19.<br/><br/>Les gagnants peuvent être tenus de passer un test COVID 19 si cela s'avère nécessaire. Les gagnants doivent se conformer à toutes les exigences liées à la COVID19.",
		article9: "Article 9: Contact",
		article9_text:
			"Aucune correspondance non conforme n'est prise en compte (incomplète, illisible, inexacte, non financée, reportée ou non envoyée).",
		article10: "Article 10: Disqualification",
		article10_text:
			"Les participants autorisent toutes les vérifications concernant leur identité, leur âge, leur adresse et leur numéro de téléphone dans le cadre du concours. Toute participation qui ne répond pas à ces exigences, incomplète ou avec des coordonnées incorrectes sera considérée comme invalide.<br/>L'utilisation de robots ou toute autre méthode similaire pour jouer au jeu mécaniquement ou autrement est interdite, la violation de cette règle entraîne l'élimination éventuelle du participant et / ou de l'utilisateur. L'Organisateur peut annuler le concours en totalité ou en partie s'il apparaît que la fraude a eu lieu sous quelque forme que ce soit, via l'ordinateur ou le fait de la participation sous l'identité d'un tiers et / ou la détermination des gagnants.Dans ce cas, l'Organisateur se réserve le droit de ne pas attribuer de prix aux fraudeurs et / ou de procéder les auteurs de cette fraude devant les tribunaux compétents.",
		article11: "Article 11: Force majeure",
		article11_text:
			"L'Organisateur n'est pas responsable en cas de force majeure ou d'événements sur lesquels il n'a aucun contrôle ou si les circonstances l'exigent, il est obligé d'annuler le présent concours, de raccourcir, prolonger, reporter, suspendre ou modifier les conditions.<br/>Dans tous les cas, l'organisateur se réserve le droit de prolonger la période de participation.",
		article12: "Article 12: Exemption de responsabilité",
		article12_text:
			"Les gagnants sont entièrement responsables de l'utilisation de leur prix. L'organisateur ne peut être tenu responsable des défauts ou des problèmes avec les prix ou de tout incident ou accident qui pourrait arriver aux gagnants pendant ou en raison de l'utilisation ou la possession du prix.",
		article13:
			"Article 13: Autorisation d'utiliser les noms et adresses des gagnants",
		article13_text:
			"Sous réserve de l'obtention de l'autorisation expresse du participant principal, l’Organisateur peut utiliser le nom et l'adresse e-mail des gagnants à des fins publicitaires sans droit à des honoraires ni transfert d'autres avantages.",
		article14: "Article 14: Droits de propriété intellectuelle et droits d'auteur",
		article14_text:
			"Conformément aux lois relatives aux droits de propriété intellectuelle et aux droits d'auteur, l'utilisation de tout ou partie du matériel protégé par le droit d'auteur de ce concours est strictement interdite, sauf accord préalable et explicite de l'Organisateur.",
		article15: "Article 15: Loi applicable et litiges",
		article15_text:
			"Le concours est soumis à la loi belge (Belgique) ou au droit luxembourgeois (Luxembourg).<br/>Tout litige relatif à l'interprétation des règles et / ou des cas non prévus au présent règlement fera l'objet d'un règlement à l'amiable.Dans le cas contraire, la compétence exclusive des tribunaux d'Anvers (Belgique) / Luxembourg (Luxembourg).",
		article16: "Article 16: Protection des données personnelles",
		article16_text:
			"L’Organisateur agira conformément aux règlementations applicables sur la protection des données.<br/><br/>Nous utilisons les données personnelles que nous recueillons à votre sujet pour vous permettre de participer au concours et vous contacter si vous avez gagné un prix.Les informations que nous collectons à votre sujet ne seront collectées que dans le but d'identifier le(s) gagnant(s). Ces données seront sauvegardées que pendant la durée du concours (terme du concours tel que défini à l'article 2 + période pendant laquelle le(s) gagnant(s) et les prix sont attribués).Consultez notre Notice Vie Privée pour plus d'informations sur la façon dont nous traitons les données personnelles.",

		// Privacy policy //
		privacy_title1: "About this Privacy Notice",
		privacy_text1:
			"Pelsis are committed to being transparent and protecting your data; therefore, this notice includes how we store and protect your data, who we share your data with and how long we hold your data for. This notice also outlines your rights and the actions you should take if you are concerned with the way Pelsis is handling your personal data. Should we ask you to provide certain information by which you can be identified, you can be assured that it will only be used in accordance with this privacy notice.",
		privacy_title2: "Who we are",
		privacy_text2:
			"Pelsis Limited, with its headquarters at Sterling House, Grimbald Crag Close, Knaresborough, HG5 8PJ, is the ‘data controller’ under the General Data Protection Regulations (referred to as GDPR throughout this document) and is responsible for the processing of client data.<br/><br/>Should you have any queries or questions regarding anything in this document or anything further please contact <a href='mailto:GDPR@pelsis.com;' target='_blank'>GDPR@pelsis.com</a>",
		privacy_title3: "What type of personal data may be collected by Pelsis",
		privacy_text3:
			"• Company name and address<br/>• Contact name<br/>• Email address, website and telephone number<br/>• VAT number<br/>• Payment details – card details and/ or bank details<br/>• Training certificates and photo IDs",
		privacy_title4: "How Pelsis collect information about you",
		privacy_text4:
			"• When you place a sales order with our sales team<br/>• When you request an online account<br/>• When you make an enquiry with any of our internal departments<br/>• When you submit an enquiry though our online enquiry form<br.• When making a booking for the use of The Pelsis Academy<br/>• When we receive your photo ID and training certificates",
		privacy_title5:
			"How Pelsis use your personal information and the lawful basis for doing so",
		privacy_text5:
			"Pelsis will only use your personal data in the following circumstances:<br/><br/>• Where we need to perform the contract we are about to enter into or have entered into with you<br/>• Where it is necessary for our legitimate interests and your interests and fundamental rights do not override those interests<br/>• Where we need to comply with a legal or regulatory obligation<br/><br/>The data may be used for the following purposes:<br/><br/>• To supply the products and/ or services requested by you<br/>• To manage your account within the Pelsis group<br/>• To enable us to contact you<br/>• To enable us to notify you of any changes to products, services or prices<br/>• To carry out market research and product analysis",
		privacy_title6: "Marketing",
		privacy_text6:
			"If you have given your explicit consent, we may use your information to contact you by post, telephone, email or text for:<br/><br/>• Your views on current services that we provide<br/>• Notifying you of important changes or developments to our business<br/>• Letting you know about products or services that may be of interest of you<br/>• Outlining promotional activity<br/>• Inviting you to events that we may be holding<br/><br/>If you have provided us with a business card, we will add you to our marketing list.<br/><br/>If you have not given us your consent then we will not contact you regarding anything other than your account with Pelsis.<br/><br/>You may withdraw your consent at any time by clicking on the ‘unsubscribe’ link at the bottom of the marketing emails or by contacting <a href='mailto:market@pelsis.com' target='_blank'>market@pelsis.com</a>",
		privacy_title7: "How Pelsis share your personal data",
		privacy_text7:
			"Pelsis do have to share your data with a restricted, trusted number of third party companies who we ensure are GDPR compliant.<br/><br/>Companies we may share information with are:<br/><br/>• With MailChimp*, printing and mail distributors in order to send you marketing communications<br/>• Transport companies so that they can deliver your goods quickly and efficiently<br/>• Suppliers in case we deliver goods directly to you<br/>• Company solicitors if we ever need to recover an outstanding debt<br/>• Credit checking companies if you have consented, completed and signed a credit application form<br/>• Training providers such as RSPH, if your personal details are requested to register you on courses and send certificates upon completion<br/><br/>*MailChimp’s servers are located in the United States so information may be transferred to, stored, or processed in the United States.However, MailChimp participates in and has certified its compliance with the EU- US Privacy Shield Framework and the Swiss- U.S.Privacy Shield Framework.<br/><br/>Pelsis may transfer your personal data outside the European Economic Area (EEA) and your data may be transferred to the UK from outside the EEA.If this happens, we will make sure that suitable safeguards are in place and that your personal data is protected at the same level that it is in the UK.",
		privacy_title8: "How long does Pelsis keep your data for",
		privacy_text8:
			"The above personal data will stay on file for as long as we have reasonable business needs and we will retain the personal data in line with legal and regulatory requirements or guidance.<br/><br/>Your online account information will be kept for as long as your account is active or as needed to provide services.If you no longer wish to have an active online account please request your account to be closed and your data will be deleted.<br/><br/>You may request for your personal data to be removed from Pelsis and its services by contacting <a href='mailto:market@pelsis.com' target='_blank'>market@pelsis.com</a>",
		privacy_title9: "Storage, security and transfers",
		privacy_text9:
			"Pelsis take the security and protection of your personal data very seriously. We have industry standard security measures to ensure that your personal data is secure against both external and internal threats with access to personal data restricted to those who need to process it.<br/><br/>Once your personal data has been processed, it will be securely stored on our company servers which have restricted access.<br/><br/>Your name and personal business email address will be stored on our third- party Customer Relationship Management(CRM) system which has access restricted to only those who have a business need to see this information.Our CRM is SOC 2 compliant and maintains reasonable security measures to protect your information from loss, destruction, misuse, unauthorised access or disclosure.Our CRM complies with the EU- U.S.Privacy Shield Framework and the Swiss – U.S.Privacy Shield Framework as set forth by the U.S.<br/><br/>You will need to ensure that your online account is protected against unauthorised access and choose a password that is not easily accessible.You must also make sure you sign out after using the services.",
		privacy_title10: "Links to other websites",
		privacy_text10:
			"Our website may contain links to other websites of interest. This privacy notice only applies to this website so when you link to other websites you should read their own privacy policies.",
		privacy_title11: "How we use cookies",
		privacy_text11:
			"Cookies are text files placed on your computer to collect standard internet log information and visitor behaviour information. This information is used to track visitor use of the website and to compile statistical reports on website activity.<br/><br/>For further information visit <a href='http://www.aboutcookies.org/' target='_blank'>www.aboutcookies.org</a> or <a href='http://www.allaboutcookies.org/' target='_blank'>www.allaboutcookies.org</a><br/><br/>You can set your browser not to accept cookies and the above websites tell you how to remove cookies from your browsers.However, in a few cases some of our website features may not function as a result.<br/><br/>On sites that have the Twitter feed enabled, Twitter may set cookies.For more details on what information Twitter collects, please visit their website <a href='https://twitter.com/en/privacy' target='_blank'>https://twitter.com/en/privacy</a><br/><br/>We may also embed videos from YouTube onto our websites.YouTube uses cookies to help prevent fraud and improve site experience.If you view a video, YouTube may set cookies on your computer.Please visit the YouTube page on embedding videos for more information:<br/><br/><a href='http://support.google.com/youtube/bin/answer.py?hl=en-GB&answer=171780&expand=PrivacyEnhancedMode#privacy' target='_blank'>http://support.google.com/youtube/bin/answer.py?hl=en-GB&answer=171780&expand=PrivacyEnhancedMode#privacy</a>",
		privacy_title12: "Access to your information and correction",
		privacy_text12:
			"Under the new regulations you have the right to access a copy to your own personal data that we hold. This is called a Subject Access Request (SAR) and any requests must be made in writing and sent to the Data Protection Officer, Sterling House, Grimbald Crag Close, Knaresborough, HG5 8PJ or emailed to <a href='mailto:GDPR@pelsis.com' target='_blank'>GDPR@pelsis.com</a><br/><br/>There is normally no charge for a SAR, however if the request is unfounded or excessive, Pelsis reserve the right to apply a charge.<br/><br/>Full information will be provided within one month of receipt.If the request is manifestly complex, Pelsis can extend this time period by a maximum of two months.If this is the case, you will be informed and kept up to date with the progress throughout.<br/><br/>If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible.We will promptly correct any information found to be incorrect.",
		privacy_title13: "Right to complain",
		privacy_text13:
			"We hope that you never have a reason to complain and would ask that you speak to one of our team so that we can resolve any issue before you need make a complaint. However, should you feel that the adequate action is not being taken, you have the right to inform a supervisory authority, such as the Information Commissioner’s Office (ICO) <a href=tel:+4403031231113'>(+44) 0303 123 1113</a>, <a href='https://ico.org.uk/' target='_blank'>https://ico.org.uk/</a>",
		privacy_title14: "Changes to our privacy notice",
		privacy_text14:
			"Any changes we may make to our privacy notice in the future will be posted on this page and where appropriate, we will notify you by email.",

		// Sales conditions //
		conditions_title: "CONDITIONS GÉNÉRALES DE VENTE ET DE LIVRAISON",
		conditions_about:
			"Nos conditions générales mentionnées ci-après sont les seules d'application, malgré toutes les autres conditions particulières et générales signifiées sur les bons de commande ou autres documents de l'acheteur au du donneur d'ordre.",
		conditions_title1: "ARTICLE 1. OFFRES-CONFIRMATIONS DE COMMANDE-PRIX- PREUVE.",
		conditions_text1:
			"Nos offres et confirmations de commande sont valables seulement pendant 5 jours, à moins que nous choisissions de les effectuer tout de même. Sauf stipulation contraire. Nos prix s'entendent sans engagement, hors TVA et frais de transport non compris. L’acheteur accepte que nos fichiers électroniques valent également comme preuve de ses obligations contractuelles.",
		conditions_title2: "ARTICLE 2. DÉLAI DE LIVRAISON.",
		conditions_text2:
			"Les délais de livraison qui ont été fixés ne sont pas contraignants et sont mentionnés uniquement à titre informatif, de sorte qu'un éventuel retard de livraison n'autorise en aucun cas l'acheteur à réclamer une indemnisation au à annuler la commande.",
		conditions_title3: "ARTICLE 3. ENDROIT DE LIVRAISON -RISQUE.",
		conditions_text3:
			"Toutes les livraisons sont effectuées au siège d'exploitation à Bornem. La remise des marchandises s'accomplit de manière valable par la mise à disposition des matériaux vendus à l'acheteur dans nos locaux dès que les marchandises sont individualisées. En tout cas le risque des marchandises sera à charge de l'acheteur à partir de la mise à disposition, même en cas d'une livraison rendue au de transport par le vendeur.",
		conditions_title4: "ARTICLE 4. EMBALLAGES.",
		conditions_text4:
			"Le cas échéant, nous pouvons facturer des frais d'emballage. Les emballages ne sont pas repris, sauf stipulation contraire explicite.",
		conditions_title5: "ARTICLES 5. RÉCLAMATIONS.",
		conditions_text5:
			"Pour être valables, des réclamations éventuelles concernant la quantité. La nature, la qualité et la conformité des marchandises doivent être signalées au moment de l'acceptation et au plus tard dans les 5 jours qui suivent à la réception par recommandé. Des réclamations concernant des défauts non apparents doivent être confirmées par recommandé dans les 8 jours après la découverte et au plus tard 6 mais après la livraison. En aucun cas une réclamation peut provoquer la suspension totale au partielle du paiement.",
		conditions_title6: "ARTICLE 6. RESPONSABILITÉ ET GARANTIE.",
		conditions_text6:
			"Dans tous les cas, notre responsabilité est doublement limitée, d'une part, au maximum, au remplacement des marchandises 1ivrées, à l'exclusion de route autre indemnisation pour des dommages directs ou indirects, comme, entre autres, des dommages à des personnes, des marchandises et plantations de l'acheteur au de tiers et. D’autre part, aux montants, payés par nos assureurs et qui sont couverts. Cette double restriction s'applique à la totalité des montants réclamés par Ie client et des tiers éventuels. Nous signalons formellement que nous n'acceptons aucune responsabilité pour l'efficacité des traitements au pour les dommages (consécutifs). Vu que nous ne pouvons pas contrôler les conditions ambiantes (en particulier la concentration et les quantités appliquées, les facteurs climatiques, environnementaux et du milieu, les caractéristiques des biens traités,… ). En ce qui concerne nos appareils. Nous accordons une garantie d'un an, à partir de la date d'expédition vers l'acheteur. Cette garantie couvre les pièces détachées et les heures de travail et s'applique uniquement sous réserve d'une utilisation normale. Les lampes, joints et défauts dus à une utilisation normale ne sont pas couverts par la garantie. Les réparations sous garantie sont effectuées après remise de l'appareil à notre siège d'exploitation. Les frais de transport sont à charge de l'acheteur.",
		conditions_title7: "ARTICLE 7. PAIEMENT.",
		conditions_text7:
			"Sauf stipulation contraire et écrite nos factures sont payables au comptant à notre siège d'exploitation à Bornem. Des éventuels risques de change sont uniquement à charge de l'acheteur qui devra nous les rembourser au les éviter. Sauf stipulation contraire nos représentants au agents ne sont pas habilités à encaisser en notre nom des sommes quelconques.",
		conditions_title8: "ARTICLE 8. NON-PAIEMENT.",
		conditions_text8:
			"Pour toute somme non payée par l'acheteur à !'échéance sera dû de plein droit et sans mise en demeure un taux d'intérêt pour non-paiement de 1% par mais, tout mais commencé étant considéré comme un mais complet. De plus, en cas d'un paiement tardif l'acheteur devra payer une indemnisation qui est fixée forfaitairement à 10 o/o du montant de la facture avec un minimum de€ 100.00. En outre nous nous réservons le droit de prouver les dommages réellement causés. Le non-paiement d'une seule facture à son échéance rend toutes les autres factures, même non échues, immédiatement exigibles.",
		conditions_title9: "ARTICLE 9. NOVATION DE CRÉANCE.",
		conditions_text9:
			"Le fait de tirer, d'accepter au de faire circuler des traites au d'autres documents négociables n'opère aucune novation de créance et n'implique aucune dérogation aux présentes conditions.",
		conditions_title10: "ARTICLE 10. COMPARAISON DE CRÉANCE.",
		conditions_text10:
			"Sauf stipulation contraire et écrite, toutes les créances auprès d'un même client, quels que soient leur nature juridique au les conditions qui y sont liées, font partie d'un seul contrat, dont les engagements mutuels se compensent constamment.",
		conditions_title11: "ARTICLE 11. ENGAGEMENTS.",
		conditions_text11:
			"Seulement après l'acceptation expresse et écrite par notre direction nous sommes tenus par des engagements éventuels souscrits par nos représentants au agents en dehors du cadre des présentes conditions.",
		conditions_title12: "ARTICLE 12. NON-EXÉCUTION D'UNE OBLIGATION.",
		conditions_text12:
			"En cas de non-exécution par l'acheteur de ses obligations, la vente sera résolue/suspendue de plein droit et sans mise en demeure. L'indemnisation suite à la non-exécution par l'acheteur est fixée forfaitairement à 15% du prix d'achat avec un minimum de€ 500.00. En outre nous nous réservons le droit de poursuivre l'exécution en nature et/ou de prouver les dommages réels.",
		conditions_title13: "ARTICLE 13. SOLVABILITÉ.",
		conditions_text13:
			"Si notre confiance en la solvabilité de l'acheteur est ébranlée, entre autres suite à des mesures d'exécution judiciaire prises contre l'acheteur au pour d'autres raisons apparentes, nous nous réservons Ie droit, même si les marchandises ont déjà été totalement au partiellement expédiées, de suspendre entièrement au partiellement la commande jusqu'à ce que l'acheteur délivre les garanties nécessaires pour la bonne exécution de ses engagements. En cas de refus de 1'acheteur, ceci sera considéré comme la non-exécution d'une obligation de l'acheteur telle que stipulée dans l'article 12 et cet article s'appliquera intégralement.",
		conditions_title14: "ARTICLE 14. CLAUSE DE RÉSERVE DE PROPRIÉTÉ.",
		conditions_text14:
			"Les marchandises restent notre propriété jusqu'au paiement complet aussi bien du principal, de l'indemnisation, des intérêts et des frais. Tous les risques sont à charge de l'acheteur. Nous conservons les acomptes payés en tant qu'indemnisation pour des pertes éventuelles à la revente. Si l'acheteur vend les marchandises qui nous appartiennent, même en état transformé. Il nous cède toutes les créances qui en résultent.11 s'engage à informer son cocontractant sur ce fait.",
		conditions_title15: "ARTICLE 15. COMPÉTENCE-DROIT D'APPLICATION.",
		conditions_text15:
			"En cas de contestation, les tribunaux de notre siège social, ceux de notre siège d'exploitation au ceux du domicile/siège social de l'acheteur, à notre choix, sont seuls compétents. En tout cas les tribunaux belges sont compétents et seulement le droit beige est d'application.",
		conditions_title16: "ARTICLE 16. RENIEMENT – NULLITÉ – PRIORITÉ.",
		conditions_text16:
			"La non-application d'une au de plusieurs dispositions des présentes conditions ne peut jamais être considérée par l'acheteur comme un reniement. La nullité d'un au de plusieurs articles au d'une partie d'un article ne porte pas atteinte à la validité des présentes conditions. En tout cas nos conditions sont prioritaires à celles de l'acheteur, qui-par le placement de sa commande - renonce à ses propres conditions générales et ne les revendique plus.",
	},
};
