/**
 * @generated SignedSource<<1656745cc9cb2ac3d89e7aef0034f77b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UserEditAvatarErrorType = "NOT_FOUND" | "%future added value";
export type UserEditErrorType = "ACCESS_DENIED" | "%future added value";
export type UserEditUsernameErrorType = "ALREADY_IN_USE" | "%future added value";
export type UserEditInput = {
  avatarId?: string | null;
  id: string;
  password?: string | null;
  username?: string | null;
};
export type UserProfileEditUserMutation$variables = {
  input: UserEditInput;
};
export type UserProfileEditUserMutation$data = {
  readonly user: {
    readonly edit: {
      readonly __typename: "UserEditErrors";
      readonly general: ReadonlyArray<{
        readonly error: UserEditErrorType;
      }> | null;
      readonly username: ReadonlyArray<{
        readonly error: UserEditUsernameErrorType;
      }> | null;
      readonly avatarId: ReadonlyArray<{
        readonly error: UserEditAvatarErrorType;
      }> | null;
    } | {
      readonly __typename: "User";
      readonly __typename: "User";
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  };
};
export type UserProfileEditUserMutation = {
  variables: UserProfileEditUserMutation$variables;
  response: UserProfileEditUserMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "error",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserEditErrorsGeneral",
      "kind": "LinkedField",
      "name": "general",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserEditErrorsUsername",
      "kind": "LinkedField",
      "name": "username",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserEditErrorsAvatarId",
      "kind": "LinkedField",
      "name": "avatarId",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": null
    }
  ],
  "type": "UserEditErrors",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserProfileEditUserMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserMutation",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "edit",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserProfileEditUserMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "UserMutation",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "edit",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "457b1d0aebca94b00500dd7d94949e51",
    "id": null,
    "metadata": {},
    "name": "UserProfileEditUserMutation",
    "operationKind": "mutation",
    "text": "mutation UserProfileEditUserMutation(\n  $input: UserEditInput!\n) {\n  user {\n    edit(input: $input) {\n      __typename\n      ... on UserEditErrors {\n        general {\n          error\n        }\n        username {\n          error\n        }\n        avatarId {\n          error\n        }\n      }\n      ... on User {\n        __typename\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a52e491e071fb2d3f103b414249af88e";

export default node;
