export const ValidationURI = "Validation";
export type ValidationURI = typeof ValidationURI;

export type ValidationSuccess<A> = {
  readonly _tag: "ValidationSuccess";
  readonly value: A;
};

export type ValidationDispute<E, A> = {
  readonly _tag: "ValidationDispute";
  readonly errors: E;
  readonly value: A;
};

export type ValidationRefute<E> = {
  readonly _tag: "ValidationRefute";
  readonly errors: E;
};

export type Validation<E, A> =
  | ValidationSuccess<A>
  | ValidationDispute<E, A>
  | ValidationRefute<E>;

const uniqueSymbol = Symbol();

declare module "@effect-ts/core/Prelude/HKT" {
  interface URItoKind<FC, TC, K, Q, W, X, I, S, R, E, A> {
    readonly [ValidationURI]: Validation<E, A>;
    // Workaround to avoid eslint's unused variable error.
    // In this case, eslint rule useless, because we extending the type,
    // so we don't need to use all type parameters,
    // and we can't change parameters.
    readonly [uniqueSymbol]: readonly [FC, TC, K, Q, W, X, I, S, R, E, A];
  }
}
