import React from "react";
import { useIntl } from "react-intl";
import { ImgWebp } from "../ImgWebp/ImgWebp";
import { picture_config, decor_ladybug, decor_papillon, decor_btn } from "./pictures";
import { MainStep } from "../MainStep/MainStep";

import { ReactComponent as IconCheck } from "../svg/check.svg";
import { ReactComponent as IconStep2 } from "../svg/step2.svg";
import { ReactComponent as IconStep3 } from "../svg/step3.svg";

type MainPageProps = { readonly className?: string };

const MainPage: React.FC<MainPageProps> = ({ className }) => {
	const intl = useIntl();
	const preFormattedText: string = intl.formatMessage({
		id: "mainPage_title",
	});
	const preFormattedDescriptionTitle: string = intl.formatMessage({
		id: "descriptionTitle",
	});
	const preFormattedDescriptionSubtitle: string = intl.formatMessage({
		id: "descriptionSubtitle",
	});
	const preFormattedDescriptionText: string = intl.formatMessage({
		id: "descriptionText",
	});
	const preFormattedStepsTitle: string = intl.formatMessage({
		id: "stepsTitle",
	});
	const preFormattedStep_title1: string = intl.formatMessage({
		id: "step_title1",
	});
	const preFormattedStep_title2: string = intl.formatMessage({
		id: "step_title2",
	});
	const preFormattedStep_description2: string = intl.formatMessage({
		id: "step_description2",
	});
	const preFormattedStep_description3: string = intl.formatMessage({
		id: "step_description3",
	});
	const preFormattedSteps_TextFinish: string = intl.formatMessage({
		id: "stepsTextFinish",
	});

	return (
		<main className="layout__main layout__page-main" data-test={className}>
			<div className="intro">
				<ImgWebp {...picture_config} parallax parallaxSpeed="-3"></ImgWebp>
				<div className="intro__text">
					<IconCheck className="intro__icon"></IconCheck>
					<h1
						className="intro__title"
						dangerouslySetInnerHTML={{ __html: preFormattedText }}
					></h1>
					<div className="intro__subtitle">
						{intl.formatMessage({ id: "mainPage_subtitle" })}
					</div>
				</div>
			</div>
			<div className="wrapper">
				<div className="main-description">
					<div className="main-description__container">
						<ImgWebp {...decor_ladybug} parallax parallaxSpeed="2"></ImgWebp>
						<div className="main-description__left">
							<h2
								className="main-description__title"
								dangerouslySetInnerHTML={{
									__html: preFormattedDescriptionTitle,
								}}
							></h2>
						</div>
						<div className="main-description__right">
							<strong
								className="main-description__subtitle"
								dangerouslySetInnerHTML={{
									__html: preFormattedDescriptionSubtitle,
								}}
							></strong>
							<div
								className="main-description__text"
								dangerouslySetInnerHTML={{
									__html: preFormattedDescriptionText,
								}}
							></div>
						</div>
					</div>
				</div>

				<div className="main-steps">
					<div className="main-steps__decor">
						<ImgWebp
							{...decor_papillon}
							picClassName="main-steps__decor_pic main-steps__decor_pic-1"
							parallax
							parallaxSpeed="2"
						></ImgWebp>
						<ImgWebp
							{...decor_papillon}
							picClassName="main-steps__decor_pic main-steps__decor_pic-2"
							parallax
						></ImgWebp>
					</div>
					<div className="main-steps__container">
						<h2
							className="main-steps__title"
							dangerouslySetInnerHTML={{
								__html: preFormattedStepsTitle,
							}}
						></h2>
						<div className="main-steps__inner">
							<ImgWebp {...decor_ladybug}></ImgWebp>
							<div className="steps">
								<MainStep
									icon={<IconStep2 className="step__icon"></IconStep2>}
									title={preFormattedStep_title1}
									text={preFormattedStep_description2}
								></MainStep>
								<MainStep
									icon={<IconStep3 className="step__icon"></IconStep3>}
									title={preFormattedStep_title2}
									text={preFormattedStep_description3}
								></MainStep>
							</div>
							<div className="main-steps__btn">
								<ImgWebp {...decor_btn}></ImgWebp>
								<span
									className="main-steps__finish"
									dangerouslySetInnerHTML={{
										__html: preFormattedSteps_TextFinish,
									}}
								></span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	);
};

export default MainPage;
