/**
 * @generated SignedSource<<61629cf86670bb1d0d6b8ba3985e3a33>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthLoginErrorType = "WRONG_CREDENTIALS" | "%future added value";
export type SignIn_SignIn_Mutation$variables = {
  username: string;
  password: string;
  restrictedByIP: boolean;
  lifeTime: number;
};
export type SignIn_SignIn_Mutation$data = {
  readonly auth: {
    readonly logIn: {
      readonly __typename: "AuthLogin";
      readonly token: string;
    } | {
      readonly __typename: "AuthLoginErrors";
      readonly general: ReadonlyArray<{
        readonly error: AuthLoginErrorType;
      }> | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  };
};
export type SignIn_SignIn_Mutation = {
  variables: SignIn_SignIn_Mutation$variables;
  response: SignIn_SignIn_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lifeTime"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "restrictedByIP"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "username"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutation",
    "kind": "LinkedField",
    "name": "auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "lifeTime",
            "variableName": "lifeTime"
          },
          {
            "kind": "Variable",
            "name": "password",
            "variableName": "password"
          },
          {
            "kind": "Variable",
            "name": "restrictedByIP",
            "variableName": "restrictedByIP"
          },
          {
            "kind": "Variable",
            "name": "username",
            "variableName": "username"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "logIn",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
              }
            ],
            "type": "AuthLogin",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "AuthLoginErrorsGeneral",
                "kind": "LinkedField",
                "name": "general",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "error",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "AuthLoginErrors",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignIn_SignIn_Mutation",
    "selections": (v4/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SignIn_SignIn_Mutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "d1521add8ef9402a2bc4b2cdbca62bf9",
    "id": null,
    "metadata": {},
    "name": "SignIn_SignIn_Mutation",
    "operationKind": "mutation",
    "text": "mutation SignIn_SignIn_Mutation(\n  $username: String!\n  $password: String!\n  $restrictedByIP: Boolean!\n  $lifeTime: Int!\n) {\n  auth {\n    logIn(username: $username, password: $password, restrictedByIP: $restrictedByIP, lifeTime: $lifeTime) {\n      __typename\n      ... on AuthLogin {\n        token\n      }\n      ... on AuthLoginErrors {\n        general {\n          error\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "28f83f246a8788f6c90117a581125630";

export default node;
