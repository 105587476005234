import React from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";

type ModalProps = {
	readonly isOpen?: boolean;
	readonly onClose?: () => void;
	readonly message?: string | JSX.Element;
};

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, message }: ModalProps) => {
	return (
		<TransitionGroup component={null}>
			{isOpen && (
				<CSSTransition classNames="modal" timeout={300}>
					<div className="modal__overlay" onClick={onClose}>
						<div className="modal__inner">{message}</div>
					</div>
				</CSSTransition>
			)}
		</TransitionGroup>
	);
};

export default Modal;
