import React from "react";
import { FormattedMessage } from "react-intl";

type SalesConditionsProps = { readonly className?: string };

const SalesConditions: React.FC<SalesConditionsProps> = () => {
	return (
		<div className="inner-page">
			<h2>
				<FormattedMessage id="conditions_title"></FormattedMessage>
			</h2>
			<p>
				<FormattedMessage id="conditions_about"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title1"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text1"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title2"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text2"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title3"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text3"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title4"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text4"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title5"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text5"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title6"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text6"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title7"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text7"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title8"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text8"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title9"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text9"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title10"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text10"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title11"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text11"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title12"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text12"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title13"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text13"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title14"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text14"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title15"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text15"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="conditions_title16"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="conditions_text16"></FormattedMessage>
			</p>
		</div>
	);
};

export default SalesConditions;
