import { eff, tsPattern } from "@shared-lib/src";
import graphql from "babel-plugin-relay/macro";
import { Suspense, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import {
	GraphQLTaggedNode,
	loadQuery,
	PreloadedQuery,
	RelayEnvironmentProvider,
	usePreloadedQuery,
} from "react-relay";
import { Route, Routes, useSearchParams } from "react-router-dom";
import { RecoilRoot, useRecoilState, useRecoilValue } from "recoil";
import { appPrimaryTheme } from "./App.css";
import { GeneralErrorBoundary } from "./GeneralErrorBoundary/GeneralErrorBoundary";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";
import { useRefreshToken } from "./RefreshToken/useRefreshToken";
import { relayEnvironment } from "./relay-environment";
import { AppPage } from "./Router/AppPage";
import { appPaths } from "./Router/Router";
import { isAuthenticatedSelector, tokenState } from "./SignIn/SignIn";
import { mkBrandedQuery } from "./util/mk-branded-query";
import { useSyncAtomicRef } from "./util/use-sync-atomic-ref";
import { AppQuery } from "./__generated__/AppQuery.graphql";
import "./mainStyle/Main.scss";
import TagManager from "react-gtm-module";

const appQuery_ = graphql`
	query AppQuery {
		auth {
			currentUser {
				__typename
				...AppPageCurrentUserFragment
				...SignInCurrentUserFragment
				... on AuthError {
					error
				}
			}
		}
	}
`;

export const appQuery: eff.branded.Branded<GraphQLTaggedNode, AppQuery> =
	mkBrandedQuery<AppQuery>(appQuery_);

const initialQueryRef = loadQuery<AppQuery>(relayEnvironment, appQuery, {});

export type AppProps = {
	readonly initialQueryRef: PreloadedQuery<AppQuery>;
};

export const App = ({ initialQueryRef }: AppProps) => {
	const {
		auth: { currentUser },
	} = usePreloadedQuery(appQuery, initialQueryRef);

	const isAuthenticated = useRecoilValue(isAuthenticatedSelector);
	const [token, setToken] = useRecoilState(tokenState);
	const tokenRef = useSyncAtomicRef(token);
	const [searchParams] = useSearchParams();

	const [currentLocale, setCurrentLocale] = useState(
		searchParams.get("lang") === "fr" ? LOCALES.FRENCH : LOCALES.DUTCH,
	);

	const tagManagerArgs = {
		gtmId: "GTM-N2XZZ7R",
	};

	process.env.APP_ENV === "live" && TagManager.initialize(tagManagerArgs);

	useRefreshToken();

	useEffect(() => {
		if (
			isAuthenticated &&
			currentUser.__typename === "AuthError" &&
			currentUser.error === "NOT_AUTHENTICATED"
		) {
			setToken({
				...tokenRef.get,
				token: "",
			});
		}
	}, [setToken, tokenRef, currentUser, isAuthenticated]);

	return (
		<RecoilRoot>
			<IntlProvider
				messages={messages[currentLocale]}
				locale={currentLocale}
				defaultLocale={LOCALES.DUTCH}
			>
				<div className="App layout">
					{/* <div className="wrapper">
						<FormattedMessage id="mainPage_subtitle"></FormattedMessage>
						</div> */}
					<div className="layout__header">
						{/* {console.log(currentLocale)} */}
						{/* <Header setCurrentLocal={setCurrentLocale} currentLang={currentLocale}></Header> */}

						<Header
							setCurrentLocal={setCurrentLocale}
							currentLocale={currentLocale}
						></Header>
						{/* <button
							onClick={() => {
							hangeChange("FRENCH");
							}}
							disabled={currentLocale === "FRENCH"}
							>
							FR
							</button>
							<button
							onClick={() => {
							hangeChange("DUTCH");
							}}
							disabled={currentLocale === "DUTCH"}
							>
							NL
							</button> */}
					</div>

					<Routes>
						{appPaths.map((path) =>
							tsPattern
								.match(path)
								.with("/", (path) => (
									<Route
										key={path}
										path={path}
										element={
											<AppPage
												pageProps={{
													path,
													currentUser,
												}}
											/>
										}
									></Route>
								))
								.with("/sign-in", (path) => (
									<Route
										key={path}
										path={path}
										element={
											<AppPage
												pageProps={{
													path,
													currentUser,
													queries: [{ query: appQuery }],
												}}
												currentUser={currentUser}
											/>
										}
									></Route>
								))
								.with("/sign-out", (path) => (
									<Route
										key={path}
										path={path}
										element={
											<AppPage
												pageProps={{
													path,
													currentUser,
												}}
											/>
										}
									></Route>
								))
								.with("/profile", (path) => (
									<Route
										key={path}
										path={path}
										element={
											<AppPage
												pageProps={{
													path,
													currentUser,
													queries: [{ query: appQuery }],
												}}
											/>
										}
									></Route>
								))
								.with("/form", (path) => (
									<Route
										key={path}
										path={path}
										element={
											<AppPage
												pageProps={{
													path,
													currentUser,
												}}
											/>
										}
									></Route>
								))
								.with("/competition-rules", (path) => (
									<Route
										key={path}
										path={path}
										element={
											<AppPage
												pageProps={{
													path,
													currentUser,
												}}
											/>
										}
									></Route>
								))
								.with("/privacy-policy", (path) => (
									<Route
										key={path}
										path={path}
										element={
											<AppPage
												pageProps={{
													path,
													currentUser,
												}}
											/>
										}
									></Route>
								))
								.with("/cookie-policy", (path) => (
									<Route
										key={path}
										path={path}
										element={
											<AppPage
												pageProps={{
													path,
													currentUser,
												}}
											/>
										}
									></Route>
								))
								.with("/sales-conditions", (path) => (
									<Route
										key={path}
										path={path}
										element={
											<AppPage
												pageProps={{
													path,
													currentUser,
												}}
											/>
										}
									></Route>
								))
								.exhaustive(),
						)}
					</Routes>
					<div className="layout__footer">
						<Footer
							setCurrentLocal={setCurrentLocale}
							currentLocale={currentLocale}
						></Footer>
					</div>
				</div>
			</IntlProvider>
		</RecoilRoot>
	);
};

export const AppRoot = () => {
	return (
		<GeneralErrorBoundary>
			<RecoilRoot>
				<RelayEnvironmentProvider environment={relayEnvironment}>
					<Suspense fallback={"Loading..."}>
						<div className={appPrimaryTheme}>
							<App initialQueryRef={initialQueryRef} />
						</div>
					</Suspense>
				</RelayEnvironmentProvider>
			</RecoilRoot>
		</GeneralErrorBoundary>
	);
};
