import {
	Navigate as OriginalNavigate,
	NavigateOptions,
	NavigateProps,
	useNavigate,
} from "react-router-dom";
import { AppPath, mkPath, Path, PathParams } from "./Router";

export type TypedNavigateProps<ToPath extends Path, FromPath extends Path> = Omit<
	NavigateProps,
	"to"
> & {
	readonly to: ToPath;
	readonly from?: FromPath;
} & (PathParams<ToPath> extends undefined
		? { readonly toParams?: PathParams<ToPath> }
		: { readonly toParams: PathParams<ToPath> }) &
	(PathParams<FromPath> extends undefined
		? { readonly fromParams?: PathParams<FromPath> }
		: { readonly fromParams: PathParams<FromPath> });

export const TypedNavigate = <ToPath extends Path, FromPath extends Path>(
	props: TypedNavigateProps<ToPath, FromPath>,
) => {
	return (
		<OriginalNavigate
			{...(props as Omit<
				TypedNavigateProps<ToPath, FromPath>,
				"toParams" | "fromParams"
			>)}
			to={mkPath({ to: props.to, params: props.toParams })}
			from={
				props.from &&
				mkPath({
					to: props.from,
					params: props.fromParams,
				})
			}
		/>
	);
};

export const Navigate = TypedNavigate;

export const useTypedNavigate = () => {
	const navigate = useNavigate();

	return <T extends number | AppPath>(
		path: T,
		options?: T extends number ? never : NavigateOptions,
	) => {
		if (typeof path === "number") {
			navigate(path);
			return;
		}

		navigate(mkPath(path), options);
	};
};
