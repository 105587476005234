import { when } from "ts-pattern";
import { GuardPattern } from "ts-pattern/lib/types/Pattern";
import { eff } from "./effect-ts-abbr";
import { RelayFutureAddedValue } from "./relay-future-proof";
import { UnionToTuple } from "./union-to-tuple";

export * as tsPattern from "ts-pattern";

/**
 * Matches unexpected value by union type.
 *
 * This way, value that does not match `possibleValues` works as default branch.
 *
 * It is useful with relay's `%other` and `%future added value` (see https://github.com/relay-tools/relay-compiler-language-typescript/issues/203#issuecomment-698443972).
 */
export const whenUnexpectedUnion: <T>(
  possibleValues: UnionToTuple<Exclude<T, RelayFutureAddedValue>>,
) => GuardPattern<string, RelayFutureAddedValue> = (possibleValues) =>
  when(
    (value: string): value is RelayFutureAddedValue =>
      !(possibleValues as readonly string[]).includes(value),
  );

export const whenNonEmptyArray: <T>() => GuardPattern<
  readonly T[] | null | undefined,
  eff.nonEmptyArray.NonEmptyArray<T>
> = <T>() =>
  when(
    (
      value: readonly T[] | null | undefined,
    ): value is eff.nonEmptyArray.NonEmptyArray<T> =>
      eff.fn.pipe(
        value,
        eff.option.fromNullable,
        eff.option.chain((value) => eff.fn.pipe(value, eff.nonEmptyArray.fromArray)),
        eff.option.isSome,
      ),
  );
