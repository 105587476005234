import { eff } from "@shared-lib/src";
import React, { useEffect, useState, Fragment, useRef } from "react";
import { ImgWebpProps } from "./imgType";

import Rellax from "rellax";

export const ImgWebp = ({
	src,
	orientationChange,
	sizes,
	imgClassName,
	picClassName,
	disableLazy,
	parallax,
	parallaxSpeed,
}: ImgWebpProps) => {
	const loading = disableLazy ? "eager" : "lazy";

	const [imgSizes, setImgSizes] = useState({ w: sizes?.w, h: sizes?.h });

	const parallaxRef = useRef<HTMLImageElement | null>(null);

	useEffect(() => {
		if (!parallax || !parallaxRef.current) return;

		const rellax = new Rellax(parallaxRef.current, {
			center: true,
			speed: Number(parallaxSpeed ? parallaxSpeed : "1"),
		});
		return () => {
			rellax.destroy();
		};
	}, [parallax, parallaxSpeed]);

	useEffect(() => {
		const changeSizes = () => {
			const dimensions = {
				height: window.innerHeight,
				width: window.innerWidth,
			};
			if (orientationChange) {
				if (dimensions.height > dimensions.width) {
					// console.log({ w: sizes?.wPortrait, h: sizes?.hPortrait });
					setImgSizes({ w: sizes?.wPortrait, h: sizes?.hPortrait });
				} else {
					setImgSizes({ w: sizes?.w, h: sizes?.h });
				}
			}
		};
		changeSizes();

		window.addEventListener("resize", changeSizes);
		return () => {
			window.removeEventListener("resize", changeSizes, true);
		};
	}, [
		orientationChange,
		setImgSizes,
		sizes?.h,
		sizes?.w,
		sizes?.wPortrait,
		sizes?.hPortrait,
	]);

	return (
		<picture className={picClassName}>
			{Object.keys(src).length > 1 &&
				Object.entries(src).map((t, k) => {
					if (t[0] !== "main") {
						const imgType = eff.fn.pipe(
							t[1].split("."),
							eff.array.last,
							eff.option.fold(
								() => "",
								(value) => `image/${value}`,
							),
						);
						const is2x = /@2x/g.test(t[1]) ? " 2x" : "";
						const media = orientationChange
							? /@_landscape/gi.test(t[1])
								? "(orientation: landscape)"
								: "(orientation: portrait)"
							: "all";

						return (
							<source
								key={k}
								srcSet={t[1] + is2x}
								type={imgType}
								media={media}
							></source>
						);
					} else {
						return <Fragment key={k}></Fragment>;
					}
				})}
			<img
				className={imgClassName}
				src={src.main}
				alt=""
				width={imgSizes.w}
				height={imgSizes.h}
				draggable="false"
				loading={loading}
				ref={parallaxRef}
			/>
		</picture>
	);
};
