import { _A, _E } from "@effect-ts/core/Effect";
import { NoSuchElementException } from "@effect-ts/system/GlobalExceptions";
import * as Utils from "@effect-ts/core/Utils";
import type { Validation } from "./core";
import { NonEmptyArray, Option, pipe } from "@effect-ts/core";
import * as Op from "./operations";
import { UnsafeDisableTypeCheckHere } from "../unsafe-disable-type-check-here";

export class GenValidation<E, A> {
  readonly [_E]: () => E = (): UnsafeDisableTypeCheckHere => {};
  readonly [_A]: () => A = (): UnsafeDisableTypeCheckHere => {};

  constructor(readonly effect: Validation<E, A>) {}

  *[Symbol.iterator](): Generator<GenValidation<E, A>, A, A> {
    return yield this;
  }
}

const adapter: ValidationGenFn = <E, A>(
  _:
    | Option.Option<A>
    | Validation<NonEmptyArray.NonEmptyArray<E | NoSuchElementException>, A>,
  __?: () => NonEmptyArray.NonEmptyArray<E | NoSuchElementException>,
) => {
  if (Utils.isOption(_)) {
    return new GenValidation(
      pipe(
        _,
        Option.fold(
          () => Op.refute(__ ? __() : [new NoSuchElementException()]),
          Op.succeed,
        ),
      ),
    );
  }

  return new GenValidation(_);
};

type ValidationGenFn = {
  <E, A>(
    _: Option.Option<A>,
    onNone: () => NonEmptyArray.NonEmptyArray<E>,
  ): GenValidation<NonEmptyArray.NonEmptyArray<E>, A>;
  <A>(_: Option.Option<A>): GenValidation<
    NonEmptyArray.NonEmptyArray<NoSuchElementException>,
    A
  >;
  <E, A>(_: Validation<NonEmptyArray.NonEmptyArray<E>, A>): GenValidation<
    NonEmptyArray.NonEmptyArray<E>,
    A
  >;
};

export const gen = <Eff extends GenValidation<unknown, unknown>, AEff>(
  f: (i: ValidationGenFn) => Generator<Eff, AEff, unknown>,
): Validation<Utils._E<Eff>, AEff> => {
  const iterator = f(adapter);
  const state = iterator.next();

  const run = (
    state: IteratorYieldResult<Eff> | IteratorReturnResult<AEff>,
  ): Validation<NonEmptyArray.NonEmptyArray<unknown>, AEff> => {
    if (state.done) {
      return Op.succeed(state.value);
    }

    return pipe(
      state.value["effect"] as Validation<NonEmptyArray.NonEmptyArray<unknown>, AEff>,
      Op.chain((val) => {
        const next = iterator.next(val);

        return run(next);
      }),
    );
  };

  return run(state) as Validation<Utils._E<Eff>, AEff>;
};
