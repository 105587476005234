import { AtomicReference } from "@effect-ts/system/Support/AtomicReference";
import { UseFormRegisterReturn } from "react-hook-form";

export const registerWithRef = <T extends UseFormRegisterReturn, Input>(
	ref: AtomicReference<Input>,
	register: T,
): T => {
	return {
		...register,
		ref: (input: Input) => {
			register.ref(input);
			ref.set(input);
		},
	};
};
