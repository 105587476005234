/**
 * @generated SignedSource<<d516703a98455bce25cf9e54e12df323>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AuthErrorType = "NOT_AUTHENTICATED" | "%future added value";
export type useRefreshTokenMutation$variables = {};
export type useRefreshTokenMutation$data = {
  readonly auth: {
    readonly refresh: {
      readonly __typename: "AuthRefresh";
      readonly token: string;
    } | {
      readonly __typename: "AuthError";
      readonly error: AuthErrorType;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  };
};
export type useRefreshTokenMutation = {
  variables: useRefreshTokenMutation$variables;
  response: useRefreshTokenMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutation",
    "kind": "LinkedField",
    "name": "auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "refresh",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
              }
            ],
            "type": "AuthRefresh",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "error",
                "storageKey": null
              }
            ],
            "type": "AuthError",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useRefreshTokenMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useRefreshTokenMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "ab1081a83962a322a399c112b2669eb5",
    "id": null,
    "metadata": {},
    "name": "useRefreshTokenMutation",
    "operationKind": "mutation",
    "text": "mutation useRefreshTokenMutation {\n  auth {\n    refresh {\n      __typename\n      ... on AuthRefresh {\n        token\n      }\n      ... on AuthError {\n        error\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c6ef1e49ff62f06a0fa788db16c36f2d";

export default node;
