// thanks https://dev.to/0916dhkim/type-safe-usage-of-react-router-5c44
import { eff, OmitWithValue } from "@shared-lib/src";

export const appProtectedPaths = ["/profile"] as const;
export const appNonProtectedPaths = [
	"/",
	"/sign-in",
	"/sign-out",
	"/form",
	"/competition-rules",
	"/privacy-policy",
	"/cookie-policy",
	"/sales-conditions",
] as const;
export const appPaths = [...appNonProtectedPaths, ...appProtectedPaths] as const;

type ExtractRouteParams<T> = T extends `${infer _Start}:${infer Param}/${infer Rest}`
	? { readonly [k in Param | keyof ExtractRouteParams<Rest>]: string }
	: T extends `${infer _Start}:${infer Param}`
	? { readonly [k in Param]: string }
	: undefined;

export type Path = typeof appPaths[number];

export type PathParams<P extends Path> = ExtractRouteParams<P>;

export type AppPath<
	P extends Path = Path,
	Params extends PathParams<P> = PathParams<P>,
> = OmitWithValue<
	{
		readonly to: P;
		readonly params: Params;
	},
	undefined
>;

/**
 * Build an url with a path and its parameters.
 *
 * @example
 * mkPath({to: '/a/:first/:last', params: { first: 'p', last: 'q' }})
 * // returns '/a/p/q'
 *
 * @param path target path.
 * @param params parameters.
 */
export const mkPath = <P extends AppPath>(path: P): string =>
	eff.fn.pipe(
		(path as unknown as { readonly params: Record<string, unknown> }).params || {},
		eff.dict.reduceWithIndex(path.to as string, (acc, key, value) =>
			acc.replace(`:${key}`, value as string),
		),
	);

export const isParams = <P extends Path>(
	path: P,
	params: unknown,
): params is PathParams<P> =>
	params && typeof params === "object"
		? eff.fn.pipe(
				path.match(/:[^/]+/g) || [],
				eff.array.find((key) => key in params === false),
				eff.option.isNone,
		  )
		: false;
