import { AtomicReference } from "@effect-ts/system/Support/AtomicReference";
import { eff } from "@shared-lib/src";
import {
	authMiddleware,
	errorMiddleware,
	progressMiddleware,
	RelayNetworkLayer,
	RelayRequestAny,
	retryMiddleware,
	uploadMiddleware,
	urlMiddleware,
} from "react-relay-network-modern/es";
import { Environment, RecordSource, Store } from "relay-runtime";
import { isSSR } from "./is-ssr";

export const TOKEN_STORAGE_KEY = "token" as const;

// We don't have access to recoil store, so we use mutable reference to store token.
// It is needed, because we need use relay environment outside of react context to provide pre-loaded queries.
export const tokenRef = new AtomicReference("");

const token = isSSR
	? undefined
	: localStorage.getItem(TOKEN_STORAGE_KEY) ||
	  sessionStorage.getItem(TOKEN_STORAGE_KEY);

if (token) {
	tokenRef.set(token);
}

const network = new RelayNetworkLayer([
	// cacheMiddleware({
	//   size: 100, // max 100 requests
	//   ttl: 900000, // 15 minutes
	// }),
	urlMiddleware({
		url: (isSSR ? "http://backend:3000" : "") + "/@@/backend/graphql",
	}),
	// process.env.NODE_ENV === "development" ? loggerMiddleware() : null,
	process.env.NODE_ENV === "development" ? errorMiddleware() : null,
	// process.env.NODE_ENV === "development" ? perfMiddleware() : null,
	retryMiddleware({
		fetchTimeout: 15000,
		retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100,
		beforeRetry: ({ abort, attempt }) => {
			if (attempt >= 3) {
				abort();
			}
		},
		statusCodes: [500, 503, 504],
	}),
	authMiddleware({
		token: eff.fn.pipe(
			eff.reader.environment<RelayRequestAny>(),
			eff.reader.map(() => tokenRef.get),
		),
		allowEmptyToken: true,
	}),
	progressMiddleware({
		onProgress: (_runningTotal, _totalSize) => {},
	}),
	uploadMiddleware(),
]);

const source = new RecordSource();
const store = new Store(source);

export const relayEnvironment = new Environment({ network, store });
