import React, { useEffect, MouseEventHandler, useCallback, useState } from "react";

import { useIntl } from "react-intl";
import { ReactComponent as Chevron } from "../svg/chevron.svg";
import { useForm, SubmitHandler } from "react-hook-form";
import graphql from "babel-plugin-relay/macro";
import { Form_Add_Answer_Mutation } from "@app/__generated__/Form_Add_Answer_Mutation.graphql";
import { useMutation } from "react-relay";
import { v4 as uuid4 } from "uuid";
// import { useTypedNavigate } from "@app/Router/TypedNavigate";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "yup-phone";
import { ErrorMessage } from "@hookform/error-message";
import Tippy from "@tippyjs/react";
import { Link, useSearchParams } from "react-router-dom";
import Modal from "@app/Modal/Modal";
import { LOCALES } from "@app/i18n/locales";

enum Step {
	step1 = 1,
	step2 = 2,
	step3 = 3,
	step4 = 4,
}

enum Question1 {
	HERBI_ALARM_SPRAY = "HERBI_ALARM_SPRAY",
	DELETE_SPRAY = "DELETE_SPRAY",
}

enum Question2 {
	WESPEN = "WESPEN",
	MIEREN = "MIEREN",
}

type FormValues = {
	readonly step1?: Question1;
	readonly step2?: Question2;
	readonly step3?: number;
	readonly name?: string;
	readonly email?: string;
	readonly phone?: string;
	readonly agreement1?: boolean;
	readonly agreement2?: boolean;
	readonly language?: string;
};

// type FormInputs = {
// 	readonly singleErrorInput: string;
// };

const schema: yup.SchemaOf<FormValues> = yup
	.object()
	.shape({
		step1: yup.string().oneOf(Object.values(Question1)).required(),
		step2: yup.string().oneOf(Object.values(Question2)).required(),
		step3: yup
			.number()
			.max(2 ** 31)
			.min(0)
			.required(),
		name: yup.string().required("formStep4_ErrorName"),
		email: yup.string().email().required("formStep4_ErrorEmail"),
		// phone: yup
		// 	.number()
		// 	.max(Number.MAX_SAFE_INTEGER)
		// 	.min(Number.MIN_SAFE_INTEGER)
		// 	.required('formStep4_ErrorPhone'),
		phone: yup
			.string()
			.phone(undefined, undefined, "formStep4_ErrorPhone")
			.required(),
		agreement1: yup
			.boolean()
			.default(false)
			.oneOf([true], "formStep4_ErrorReqCheckbox"),
		agreement2: yup.boolean().default(false),
	})
	.required() as yup.SchemaOf<FormValues>;

const Form = () => {
	const [currentStep, setCurrentStep] = useState(Step.step1);

	const [searchParams] = useSearchParams();

	const form = useForm<FormValues>({
		resolver: yupResolver(schema),
		mode: "onChange",
	});

	const formStateErrors = form.formState.errors;

	const [nextStepDisabled, setNextStepDisabled] = useState(true);

	const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
	// const toggleModal = () =>{
	// 	setIsSuccessfullySubmitted(!isSuccessfullySubmitted);
	// }

	const intl = useIntl();

	useEffect(() => {
		switch (currentStep) {
			case Step.step1:
				// setNextStepDisabled(false);
				{
					const shouldBeDisabled =
						!form.formState.dirtyFields.step1 ||
						Boolean(form.formState.errors.step1);
					if (shouldBeDisabled !== nextStepDisabled) {
						setNextStepDisabled(shouldBeDisabled);
					}
				}
				break;
			case Step.step2:
				// setNextStepDisabled(false);
				{
					const shouldBeDisabled =
						!form.formState.dirtyFields.step2 ||
						Boolean(form.formState.errors.step2);
					if (shouldBeDisabled !== nextStepDisabled) {
						setNextStepDisabled(shouldBeDisabled);
					}
				}
				break;
			case Step.step3:
				{
					const shouldBeDisabled =
						!form.formState.dirtyFields.step3 ||
						Boolean(form.formState.errors.step3);
					if (shouldBeDisabled !== nextStepDisabled) {
						setNextStepDisabled(shouldBeDisabled);
					}
				}
				break;
			case Step.step4:
				{
					const shouldBeDisabled =
						!form.formState.isDirty || !form.formState.isValid;
					if (shouldBeDisabled !== nextStepDisabled) {
						setNextStepDisabled(shouldBeDisabled);
					}
				}
				break;
		}
	}, [
		currentStep,
		nextStepDisabled,
		setNextStepDisabled,
		form.formState.dirtyFields.step1,
		form.formState.dirtyFields.step2,
		form.formState.dirtyFields.step3,
		form.formState.errors.step1,
		form.formState.errors.step2,
		form.formState.errors.step3,
		form.formState.isDirty,
		form.formState.isValid,
	]);

	const nextStepHandle = useCallback<MouseEventHandler<HTMLButtonElement>>(() => {
		if (currentStep < 4) {
			setCurrentStep(currentStep + 1);
		}
	}, [setCurrentStep, currentStep]);

	const [commitAddAnswer, isAddAnswerInFlight] =
		useMutation<Form_Add_Answer_Mutation>(graphql`
			mutation Form_Add_Answer_Mutation($input: AddAnswerInput!) {
				answer {
					add(input: $input) {
						__typename
					}
				}
			}
		`);

	// const navigate = useTypedNavigate();

	const addAnswer = useCallback(
		(input: Form_Add_Answer_Mutation["variables"]["input"]) => {
			return commitAddAnswer({
				variables: { input },
				onCompleted: (_data) => {
					// navigate({ to: "/" });
					setIsSuccessfullySubmitted(true);
				},
			});
		},
		// [commitAddAnswer, navigate],
		[commitAddAnswer],
	);

	const handleFormSubmit = useCallback<SubmitHandler<FormValues>>(
		(data) => {
			addAnswer({
				id: "Answer:" + uuid4(),
				email: data.email || "",
				hiftingDemand: Number(data.step3),
				name: data.name || "",
				phone: Number(data.phone?.replace(/-?[^\d.]/g, "")),
				question1: data.step1 || Question1.HERBI_ALARM_SPRAY,
				question2: data.step2 || Question2.WESPEN,
				privacy: data.agreement1 || true,
				newsletter: data.agreement2 || false,
				language:
					searchParams.get("lang") === "fr" ? LOCALES.FRENCH : LOCALES.DUTCH,
			});
		},
		[addAnswer, searchParams],
	);

	// const toPositiveNum = (e: React.ChangeEvent<HTMLInputElement>): void =>{
	// 	e.target.value = Math.abs(Number(e.target.value));
	// }

	return (
		<main className="layout__main layout__page-form">
			<h2
				dangerouslySetInnerHTML={{
					__html: intl.formatMessage({
						id: "stepsTextFinish",
					}),
				}}
			></h2>
			<form onSubmit={form.handleSubmit(handleFormSubmit)} className="">
				<div className="formStep" data-step={currentStep}>
					<div className="formStep__info">
						<span
							dangerouslySetInnerHTML={{
								__html: intl.formatMessage({
									id: `formStep${currentStep}_info`,
								}),
							}}
						></span>
						{(currentStep === Step.step1 || currentStep === Step.step2) && (
							<Tippy
								offset={[0, 20]}
								// content={intl.formatMessage({
								// 	id: `formStep${currentStep}_infoText`,
								// })}
								interactive
								interactiveBorder={50}
								content={
									<div
										dangerouslySetInnerHTML={{
											__html: intl.formatMessage({
												id: `formStep${currentStep}_infoText`,
											}),
										}}
									></div>
								}
							>
								<i className="info">?</i>
							</Tippy>
						)}
					</div>

					{currentStep !== Step.step4 && (
						<h2
							className="formStep__title"
							dangerouslySetInnerHTML={{
								__html: intl.formatMessage({
									id: `formStep${currentStep}_Title`,
								}),
							}}
						></h2>
					)}

					{currentStep === Step.step1 && (
						<div className="formStep__checkbox-group checkbox-group">
							{[
								{
									transId: "formStep1_variantA",
									value: Question1.HERBI_ALARM_SPRAY,
								},
								{
									transId: "formStep1_variantB",
									value: Question1.DELETE_SPRAY,
								},
							].map(({ transId, value }) => (
								<label className="checkbox-group__item" key={transId}>
									<input
										type="radio"
										{...form.register(`step${currentStep}`)}
										// defaultChecked={num === 0 ? true : false}
										value={value}
									/>
									<span className="pseudo"></span>
									<span className="text">
										{intl.formatMessage({
											id: transId,
										})}
									</span>
								</label>
							))}
						</div>
					)}

					{currentStep === Step.step2 && (
						<div className="formStep__checkbox-group checkbox-group">
							{[
								{
									transId: "formStep2_variantA",
									value: Question2.WESPEN,
								},
								{
									transId: "formStep2_variantB",
									value: Question2.MIEREN,
								},
							].map(({ transId, value }) => (
								<label className="checkbox-group__item" key={transId}>
									<input
										type="radio"
										{...form.register(`step${currentStep}`)}
										// defaultChecked={num === 0 ? true : false}
										value={value}
									/>
									<span className="pseudo"></span>
									<span className="text">
										{intl.formatMessage({
											id: transId,
										})}
									</span>
								</label>
							))}
						</div>
					)}

					{currentStep === Step.step3 && (
						<label className="text-field__wrapper">
							<input
								className="text-field only-number"
								type="number"
								inputMode="numeric"
								min="0"
								// onInput={toPositiveNum}
								{...form.register("step3")}
							/>
						</label>
					)}

					{currentStep === Step.step4 && (
						<div className="fields-wrapper">
							<label className="fields-wrapper__line">
								<div className="fields-wrapper__label">
									<span>
										{intl.formatMessage({
											id: "formStep4_LabelName",
										})}
									</span>
								</div>
								<input
									className="text-field"
									type="text"
									{...form.register("name")}
								/>

								<ErrorMessage
									errors={formStateErrors}
									name="name"
									render={({ message }) => (
										<p className="error">
											{intl.formatMessage({
												id: message,
											})}
										</p>
									)}
								/>
							</label>
							<label className="fields-wrapper__line">
								<div className="fields-wrapper__label">
									<span>
										{intl.formatMessage({
											id: "formStep4_LabelEmail",
										})}
									</span>
								</div>
								<input
									className="text-field"
									type="text"
									{...form.register("email")}
								/>
								<ErrorMessage
									errors={formStateErrors}
									name="email"
									render={({ message }) => (
										<p className="error">
											{intl.formatMessage({
												id: message,
											})}
										</p>
									)}
								/>
							</label>
							<label className="fields-wrapper__line">
								<div className="fields-wrapper__label">
									<span>
										{intl.formatMessage({
											id: "formStep4_LabelPhone",
										})}
									</span>
								</div>
								<input
									className="text-field"
									type="tel"
									autoComplete="new-password"
									defaultValue="+32"
									{...form.register("phone")}
								/>
								<ErrorMessage
									errors={formStateErrors}
									name="phone"
									render={({ message }) => (
										<p className="error">
											{intl.formatMessage({
												id: message,
											})}
										</p>
									)}
								/>
							</label>

							<label className="custom-checkbox__wrapper">
								<input
									type="checkbox"
									{...form.register("agreement1")}
									// defaultChecked={true}
								/>
								<span className="pseudo"></span>
								<div
									className="text"
									dangerouslySetInnerHTML={{
										__html: intl.formatMessage({
											id: "formStep4_checkbox1",
										}),
									}}
								></div>
								<ErrorMessage
									errors={formStateErrors}
									name="agreement1"
									render={({ message }) => (
										<p className="error">
											{intl.formatMessage({
												id: message,
											})}
										</p>
									)}
								/>
							</label>

							<label className="custom-checkbox__wrapper">
								<input type="checkbox" {...form.register("agreement2")} />
								<span className="pseudo"></span>
								<div
									className="text"
									dangerouslySetInnerHTML={{
										__html: intl.formatMessage({
											id: "formStep4_checkbox2",
										}),
									}}
								></div>
							</label>
						</div>
					)}

					{currentStep !== Step.step4 && (
						<Tippy
							offset={[0, 20]}
							content={
								<div
									dangerouslySetInnerHTML={{
										__html: intl.formatMessage({
											id: `formStep${currentStep}_nextStepError`,
										}),
									}}
								></div>
							}
							disabled={!nextStepDisabled}
						>
							<div className="formStep__next-wrapper">
								<button
									type="button"
									className="formStep__next"
									onClick={nextStepHandle}
									disabled={nextStepDisabled}
								>
									<Chevron className="arrow-next"></Chevron>
								</button>
							</div>
						</Tippy>
					)}
				</div>
				{/* {currentStep === Step.step4 && isSuccessfullySubmitted && (
					<div className="text-center">
						<div
							className="statusform-success"
							dangerouslySetInnerHTML={{
								__html: intl.formatMessage({ id: "formSucces_text" }),
							}}
						></div>
						<Link to={"/"} className="formStep__finaly">
							<span
								dangerouslySetInnerHTML={{
									__html: intl.formatMessage({ id: "formSucces_btn" }),
								}}
							></span>
							<Chevron className="chevron-right"></Chevron>
						</Link>
					</div>
				)} */}
				{currentStep === Step.step4 && !isSuccessfullySubmitted && (
					<div className="text-center">
						<button
							type="submit"
							className="formStep__finaly"
							disabled={isAddAnswerInFlight}
						>
							<span
								dangerouslySetInnerHTML={{
									__html: intl.formatMessage({ id: "formStep4_btn" }),
								}}
							></span>
							<Chevron className="chevron-right"></Chevron>
						</button>
					</div>
				)}
				{/* <button onClick={toggleModal} >Toggle modal visibility</button> */}
				<Modal
					message={
						<div className="text-center">
							<div
								className="statusform-success"
								dangerouslySetInnerHTML={{
									__html: intl.formatMessage({ id: "formSucces_text" }),
								}}
							></div>
							<Link to={"/"} className="formStep__finaly">
								<span
									dangerouslySetInnerHTML={{
										__html: intl.formatMessage({
											id: "formSucces_btn",
										}),
									}}
								></span>
								<Chevron className="chevron-right"></Chevron>
							</Link>
						</div>
					}
					isOpen={isSuccessfullySubmitted}
				></Modal>
			</form>
		</main>
	);
};

export default Form;
