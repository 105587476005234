/**
 * @generated SignedSource<<7313c0cf796e898fa9c9f0ef4415fa5c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FileStorageUploadErrorType = "INTERNAL_ERROR" | "%future added value";
export type UserProfileUploadFileMutation$variables = {
  file: File;
};
export type UserProfileUploadFileMutation$data = {
  readonly fileStorage: {
    readonly upload: {
      readonly __typename: "File";
      readonly id: string;
      readonly path: string;
    } | {
      readonly __typename: "FileStorageUploadErrors";
      readonly general: ReadonlyArray<{
        readonly error: FileStorageUploadErrorType;
      }> | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    };
  };
};
export type UserProfileUploadFileMutation = {
  variables: UserProfileUploadFileMutation$variables;
  response: UserProfileUploadFileMutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "file"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "file",
    "variableName": "file"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "path",
      "storageKey": null
    }
  ],
  "type": "File",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "FileStorageUploadErrorsGeneral",
      "kind": "LinkedField",
      "name": "general",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "error",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FileStorageUploadErrors",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserProfileUploadFileMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FileStorageMutation",
        "kind": "LinkedField",
        "name": "fileStorage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "upload",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserProfileUploadFileMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FileStorageMutation",
        "kind": "LinkedField",
        "name": "fileStorage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "upload",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7372ecee36bb631b7a26f1faaa38e172",
    "id": null,
    "metadata": {},
    "name": "UserProfileUploadFileMutation",
    "operationKind": "mutation",
    "text": "mutation UserProfileUploadFileMutation(\n  $file: FileStorageUpload!\n) {\n  fileStorage {\n    upload(file: $file) {\n      __typename\n      ... on File {\n        id\n        path\n      }\n      ... on FileStorageUploadErrors {\n        general {\n          error\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c8fb3ac2bc05ee031f2008c9bc0bb1e6";

export default node;
