import React from "react";
import useScript from "@app/hooks/useScript";

const PrivacyPolicy: React.FC = () => {
	useScript(
		process.env.APP_ENV === "live" ? "https://app.termly.io/embed-policy.min.js" : "",
	);

	const termlyIoCode =
		'<div name="termly-embed" data-id="103f474c-7be3-4677-82dd-1b54084881f2" data-type="iframe"></div>';

	return (
		<div
			className="policy-page"
			dangerouslySetInnerHTML={{ __html: termlyIoCode }}
		></div>
	);
};

export default PrivacyPolicy;
