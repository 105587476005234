import { AtomicReference } from "@effect-ts/system/Support/AtomicReference";
import { useEffect } from "react";
import { useAtomicRef } from "./use-atomic-ref";

/**
 * Creates a ref, and synchronizes it's value with provided value.
 *
 * It is useful, for example, if you want to use some value inside useEffect, but don't want to re-run effect on changes of this value.
 */
export const useSyncAtomicRef = <T>(value: T): AtomicReference<T> => {
	const ref = useAtomicRef(value);

	useEffect(() => {
		ref.set(value);
	}, [value, ref]);

	return ref;
};
