import React from "react";
import { Helmet } from "react-helmet";

const HeadScripts: React.FC = () => {
	if (process.env.APP_ENV === "live") {
		return (
			<Helmet>
				<script
					type="text/javascript"
					src="https://app.termly.io/embed.min.js"
					data-auto-block="on"
					data-website-uuid="770a95e5-efaa-43df-a211-2f35de2ed6ed"
				></script>

				<script
					async
					src="https://www.googletagmanager.com/gtag/js?id=G-S79SJ0G4KQ"
				></script>
				<script>{`
					window.dataLayer = window.dataLayer || [];

					function gtag() { dataLayer.push(arguments) };
					gtag('js', new Date());

					gtag('config', 'G-S79SJ0G4KQ'); 
				`}</script>
			</Helmet>
		);
	} else {
		return <Helmet></Helmet>;
	}
};

export default HeadScripts;
