import React from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";

type CompetitionRulesProps = { readonly className?: string };

const CompetitionRules: React.FC<CompetitionRulesProps> = () => {
	const intl = useIntl();
	const preFormattedArticle3Text: string = intl.formatMessage({
		id: "article3_text",
	});
	const preFormattedArticle4Text1: string = intl.formatMessage({
		id: "article4_1_text",
	});
	const preFormattedArticle4Text2: string = intl.formatMessage({
		id: "article4_2_text",
	});
	const preFormattedArticle5Text1: string = intl.formatMessage({
		id: "article5_1_text",
	});
	const preFormattedArticle5Text2: string = intl.formatMessage({
		id: "article5_2_text",
	});
	const preFormattedArticle6Text: string = intl.formatMessage({
		id: "article6_text",
	});
	const preFormattedArticle8Text1: string = intl.formatMessage({
		id: "article8_1_text",
	});
	const preFormattedArticle8Text2: string = intl.formatMessage({
		id: "article8_2_text",
	});
	const preFormattedArticle8Text3: string = intl.formatMessage({
		id: "article8_3_text",
	});
	const preFormattedArticle8Text4: string = intl.formatMessage({
		id: "article8_4_text",
	});
	const preFormattedArticle8Text5: string = intl.formatMessage({
		id: "article8_5_text",
	});
	const preFormattedArticle8Text6: string = intl.formatMessage({
		id: "article8_6_text",
	});
	const preFormattedArticle8Text7: string = intl.formatMessage({
		id: "article8_7_text",
	});
	const preFormattedArticle8Text8: string = intl.formatMessage({
		id: "article8_8_text",
	});
	const preFormattedArticle10Text: string = intl.formatMessage({
		id: "article10_text",
	});
	const preFormattedArticle11Text: string = intl.formatMessage({
		id: "article11_text",
	});
	const preFormattedArticle15Text: string = intl.formatMessage({
		id: "article15_text",
	});
	const preFormattedArticle16Text: string = intl.formatMessage({
		id: "article16_text",
	});

	return (
		<div className="inner-page">
			<h2>
				<FormattedMessage id="competition_rules"></FormattedMessage>
			</h2>
			<h3>
				<FormattedMessage id="article1"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="article1_text"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="article2"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="article2_text"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="article3"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle3Text }}></p>

			<h3>
				<FormattedMessage id="article4"></FormattedMessage>
			</h3>
			<h3>
				<FormattedMessage id="article4_1"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle4Text1 }}></p>
			<h3>
				<FormattedMessage id="article4_2"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle4Text2 }}></p>

			<h3>
				<FormattedMessage id="article5"></FormattedMessage>
			</h3>
			<h3>
				<FormattedMessage id="article5_1"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle5Text1 }}></p>
			<h3>
				<FormattedMessage id="article5_2"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle5Text2 }}></p>

			<h3>
				<FormattedMessage id="article6"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle6Text }}></p>

			<h3>
				<FormattedMessage id="article7"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="article7_text"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="article8"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle8Text1 }}></p>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle8Text2 }}></p>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle8Text3 }}></p>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle8Text4 }}></p>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle8Text5 }}></p>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle8Text6 }}></p>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle8Text7 }}></p>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle8Text8 }}></p>

			<h3>
				<FormattedMessage id="article9"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="article9_text"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="article10"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle10Text }}></p>

			<h3>
				<FormattedMessage id="article11"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle11Text }}></p>

			<h3>
				<FormattedMessage id="article12"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="article12_text"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="article13"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="article13_text"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="article14"></FormattedMessage>
			</h3>
			<p>
				<FormattedMessage id="article14_text"></FormattedMessage>
			</p>

			<h3>
				<FormattedMessage id="article15"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle15Text }}></p>

			<h3>
				<FormattedMessage id="article16"></FormattedMessage>
			</h3>
			<p dangerouslySetInnerHTML={{ __html: preFormattedArticle16Text }}></p>
		</div>
	);
};

export default CompetitionRules;
