import {
  DSL,
  Either,
  Identity,
  NonEmptyArray,
  Option,
  pipe,
  Sync,
} from "@effect-ts/core";
import * as Tuple from "@effect-ts/core/Collections/Immutable/Tuple";
import { flow, identity } from "@effect-ts/core/Function";
import * as Prelude from "@effect-ts/core/Prelude";
import { tsPattern } from "../ts-pattern";
import { UnsafeMutableTuple } from "../unsafe-mutable-tuple";
import {
  Validation,
  ValidationDispute,
  ValidationRefute,
  ValidationSuccess,
  ValidationURI,
} from "./core";
import { V } from "./definition";

export const succeed = <A>(value: A): Validation<never, A> => ({
  _tag: "ValidationSuccess",
  value,
});

export const map =
  <B, A>(f: (a: A) => B) =>
  <E>(fa: Validation<E, A>): Validation<E, B> =>
    tsPattern
      .match(fa)
      .with({ _tag: "ValidationSuccess" }, { _tag: "ValidationDispute" }, (fa) => ({
        ...fa,
        value: f(fa.value),
      }))
      .with({ _tag: "ValidationRefute" }, identity)
      .exhaustive();

export const mapErrors =
  <B, A, E>(
    f: (errors: NonEmptyArray.NonEmptyArray<E>) => NonEmptyArray.NonEmptyArray<B>,
  ) =>
  (
    fa: Validation<NonEmptyArray.NonEmptyArray<E>, A>,
  ): Validation<NonEmptyArray.NonEmptyArray<B>, A> =>
    tsPattern
      .match(fa)
      .with(
        { _tag: "ValidationSuccess" },
        (fa) => fa as Validation<NonEmptyArray.NonEmptyArray<B>, A>,
      )
      .with({ _tag: "ValidationRefute" }, { _tag: "ValidationDispute" }, (fa) => ({
        ...fa,
        errors: pipe(fa.errors, f),
      }))
      .exhaustive();

export const chain =
  <B, E, A>(f: (a: A) => Validation<NonEmptyArray.NonEmptyArray<E>, B>) =>
  <E2>(
    fa: Validation<NonEmptyArray.NonEmptyArray<E2>, A>,
  ): Validation<NonEmptyArray.NonEmptyArray<E | E2>, B> =>
    tsPattern
      .match(fa)
      .with({ _tag: "ValidationSuccess" }, ({ value }) => f(value))
      .with({ _tag: "ValidationDispute" }, (fa) =>
        tsPattern
          .match(f(fa.value))
          .with({ _tag: "ValidationSuccess" }, (fb) => ({
            ...fa,
            value: fb.value,
          }))
          .with({ _tag: "ValidationDispute" }, { _tag: "ValidationRefute" }, (fb) => ({
            ...fb,
            errors: pipe(fa.errors, NonEmptyArray.concat<E | E2>(fb.errors)),
          }))
          .exhaustive(),
      )
      .with({ _tag: "ValidationRefute" }, (fa) => fa)
      .exhaustive();

export const zip =
  <E, B>(fb: Validation<NonEmptyArray.NonEmptyArray<E>, B>) =>
  <E2, A>(
    fa: Validation<NonEmptyArray.NonEmptyArray<E2>, A>,
  ): Validation<
    NonEmptyArray.NonEmptyArray<E | E2>,
    Tuple.Tuple<UnsafeMutableTuple<readonly [A, B]>>
  > =>
    pipe(
      fa,
      chain((a) =>
        pipe(
          fb,
          map((b) => Tuple.fromNative([a, b])),
        ),
      ),
    );

export const flatten = <E, E2, A>(
  mma: Validation<
    NonEmptyArray.NonEmptyArray<E>,
    Validation<NonEmptyArray.NonEmptyArray<E2>, A>
  >,
): Validation<NonEmptyArray.NonEmptyArray<E | E2>, A> => {
  return pipe(mma, chain(identity));
};

export const dispute =
  <A>(value: A) =>
  <E>(
    errors: NonEmptyArray.NonEmptyArray<E>,
  ): Validation<NonEmptyArray.NonEmptyArray<E>, A> => ({
    _tag: "ValidationDispute",
    errors,
    value,
  });

export const refute = <E>(
  errors: NonEmptyArray.NonEmptyArray<E>,
): Validation<NonEmptyArray.NonEmptyArray<E>, never> => ({
  _tag: "ValidationRefute",
  errors,
});

export const tolerate = <E, A>(
  fa: Validation<NonEmptyArray.NonEmptyArray<E>, A>,
): Validation<NonEmptyArray.NonEmptyArray<E>, Option.Option<A>> =>
  tsPattern
    .match(fa)
    .with(
      { _tag: "ValidationSuccess" },
      { _tag: "ValidationDispute" },
      (fa): Validation<NonEmptyArray.NonEmptyArray<E>, Option.Option<A>> =>
        pipe(fa, map(Option.some)),
    )
    .with(
      { _tag: "ValidationRefute" },
      (fa): Validation<NonEmptyArray.NonEmptyArray<E>, Option.Option<A>> => ({
        _tag: "ValidationDispute",
        value: Option.none,
        errors: fa.errors,
      }),
    )
    .exhaustive();

export const fold =
  <E extends NonEmptyArray.NonEmptyArray<unknown>, A, B1, B2>(
    onError: (errors: E, value: Option.Option<A>) => B1,
    onSuccess: (value: A) => B2,
  ) =>
  (fa: Validation<E, A>): B1 | B2 =>
    tsPattern
      .match(fa)
      .with({ _tag: "ValidationRefute" }, (fa) => onError(fa.errors, Option.none))
      .with({ _tag: "ValidationDispute" }, (fa) =>
        onError(fa.errors, Option.some(fa.value)),
      )
      .with({ _tag: "ValidationSuccess" }, (fa) => onSuccess(fa.value))
      .exhaustive();

export const orElseEither =
  <E2, B>(fb: () => Validation<NonEmptyArray.NonEmptyArray<E2>, B>) =>
  <E, A>(
    fa: Validation<NonEmptyArray.NonEmptyArray<E>, A>,
  ): Validation<NonEmptyArray.NonEmptyArray<E2 | E>, Either.Either<A, B>> =>
    pipe(
      fa,
      fold(
        (errors) =>
          pipe(
            fb(),
            mapErrors((err) => pipe(err, NonEmptyArray.concat<E2 | E>(errors))),
            fold(refute, (value) => succeed(Either.right(value))),
          ),
        (value) => succeed(Either.left(value)),
      ),
    );

export const toEither = <E, A>(
  fa: Validation<NonEmptyArray.NonEmptyArray<E>, A>,
): Either.Either<NonEmptyArray.NonEmptyArray<E>, A> =>
  pipe(
    fa,
    fold(
      (errors) => Either.left(errors),
      (value) => Either.right(value),
    ),
  );

export const isFailure = <E, A>(
  fa: Validation<E, A>,
): fa is Exclude<typeof fa, ValidationSuccess<A>> => fa._tag !== "ValidationSuccess";

export const isRefute = <E, A>(fa: Validation<E, A>): fa is ValidationRefute<E> =>
  fa._tag === "ValidationRefute";

export const isDispute = <E, A>(fa: Validation<E, A>): fa is ValidationDispute<E, A> =>
  fa._tag === "ValidationDispute";

export const isSuccess = <E, A>(fa: Validation<E, A>): fa is ValidationSuccess<A> =>
  fa._tag === "ValidationSuccess";

export const forEachF = Prelude.implementForEachF<
  UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>,
  V
>()(
  (_) => (G) => (f) => (fa) =>
    tsPattern
      .match(fa)
      .with({ _tag: "ValidationSuccess" }, { _tag: "ValidationDispute" }, (fa) =>
        pipe(fa.value, f, G.map(succeed)),
      )
      .with({ _tag: "ValidationRefute" }, (fa) =>
        DSL.succeedF(G)(
          fa as typeof f extends (a: unknown) => Prelude.HKT<unknown, infer B>
            ? Validation<NonEmptyArray.NonEmptyArray<unknown>, B>
            : never,
        ),
      )
      .exhaustive(),
);

export const foldMap: <M>(
  M: Identity.Identity<M>,
) => <A>(f: (a: A) => M) => <E>(fa: Validation<E, A>) => M = (M) => (f) => (fa) =>
  tsPattern
    .match(fa)
    .with({ _tag: "ValidationSuccess" }, { _tag: "ValidationDispute" }, (fa) =>
      f(fa.value),
    )
    .with({ _tag: "ValidationRefute" }, (_fa) => M.identity)
    .exhaustive();

export const reduce =
  <A, B>(b: B, f: (b: B, a: A) => B): (<E>(fa: Validation<E, A>) => B) =>
  (fa) =>
    tsPattern
      .match(fa)
      .with({ _tag: "ValidationSuccess" }, { _tag: "ValidationDispute" }, (fa) =>
        f(b, fa.value),
      )
      .with({ _tag: "ValidationRefute" }, (_fa) => b)
      .exhaustive();

export const reduceRight =
  <A, B>(b: B, f: (a: A, b: B) => B): (<E>(fa: Validation<E, A>) => B) =>
  (fa) =>
    tsPattern
      .match(fa)
      .with({ _tag: "ValidationSuccess" }, { _tag: "ValidationDispute" }, (fa) =>
        f(fa.value, b),
      )
      .with({ _tag: "ValidationRefute" }, (_fa) => b)
      .exhaustive();

export const chainRec: Prelude.ChainRec<
  UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>,
  V
>["chainRec"] =
  <A, B, E>(
    f: (a: A) => Validation<NonEmptyArray.NonEmptyArray<E>, Either.Either<A, B>>,
  ) =>
  (a: A): Validation<NonEmptyArray.NonEmptyArray<E>, B> =>
    Prelude.tailRec<
      Validation<NonEmptyArray.NonEmptyArray<E>, Either.Either<A, B>>,
      Validation<NonEmptyArray.NonEmptyArray<E>, B>
    >(f(a), (e) =>
      pipe(
        e,
        fold(
          (_) => Either.left(e),
          (value) =>
            pipe(
              value,
              Either.fold(() => Either.left(e), flow(succeed, Either.right)),
            ),
        ),
      ),
    );

export const toSync = <E, A>(
  fa: Validation<NonEmptyArray.NonEmptyArray<E>, A>,
): Sync.Sync<never, NonEmptyArray.NonEmptyArray<E>, A> =>
  pipe(Sync.fromEither(() => pipe(fa, toEither)));
