import { useEffect } from "react";

const useScript = function (url: string) {
	useEffect(() => {
		const script: HTMLScriptElement = document.createElement("script");

		script.setAttribute("src", url);
		script.setAttribute("async", "true");

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [url]);
};

export default useScript;
