import React from "react";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { AppRoot } from "./App";
import reportWebVitals from "./reportWebVitals";
import "tippy.js/dist/tippy.css";

const rootElement = document.getElementById("root");
if (rootElement) {
	if (process.env.NODE_ENV === "production") {
		const _root = ReactDOM.hydrateRoot(
			rootElement,
			<React.StrictMode>
				<BrowserRouter>
					<AppRoot />
				</BrowserRouter>
			</React.StrictMode>,
		);
	} else {
		const root = ReactDOM.createRoot(rootElement);
		root.render(
			<React.StrictMode>
				<BrowserRouter>
					<AppRoot />
				</BrowserRouter>
			</React.StrictMode>,
		);
	}
}

// If you want to start measuring performance in your app, pass a function
// to log results
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
