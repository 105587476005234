import React from "react";
import { useIntl } from "react-intl";
import { FormattedMessage } from "react-intl";
import { LOCALES } from "../i18n/locales";
import { Link } from "react-router-dom";

type FooterProps = {
	readonly setCurrentLocal: (value: LOCALES) => void;
	readonly currentLocale: LOCALES;
};

const Footer: React.FC<FooterProps> = ({ currentLocale }: FooterProps) => {
	const intl = useIntl();
	const preFormattedContactLink: string = intl.formatMessage({
		id: "contact",
	});

	return (
		<footer id="footer" className="footer">
			<div className="footer__container">
				<div className="footer__left">
					<ul className="footer__links">
						<li className="footer__links-item">
							<Link
								to={{
									pathname: "/competition-rules",
									search: `?lang=${currentLocale}`,
								}}
							>
								<FormattedMessage id="competition_rules"></FormattedMessage>
							</Link>
						</li>
						<li className="footer__links-item">
							<Link
								to={{
									pathname: "/privacy-policy",
									search: `?lang=${currentLocale}`,
								}}
							>
								<FormattedMessage id="privacy_policy"></FormattedMessage>
							</Link>
						</li>
						<li className="footer__links-item">
							<Link
								to={{
									pathname: "/cookie-policy",
									search: `?lang=${currentLocale}`,
								}}
							>
								<FormattedMessage id="cookie_policy"></FormattedMessage>
							</Link>
						</li>
						<li className="footer__links-item">
							<Link
								to={{
									pathname: "/sales-conditions",
									search: `?lang=${currentLocale}`,
								}}
							>
								<FormattedMessage id="sales_conditions"></FormattedMessage>
							</Link>
						</li>
						<li
							className="footer__links-item"
							dangerouslySetInnerHTML={{ __html: preFormattedContactLink }}
						></li>
					</ul>
				</div>
				<div className="footer__right">
					<p>
						<FormattedMessage id="copyright_text"></FormattedMessage>
					</p>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
