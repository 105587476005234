import { appQuery } from "@app/App";
import { useGoBack } from "@app/History/useGoBack";
import { tokenRef, TOKEN_STORAGE_KEY } from "@app/relay-environment";
import { AppPageQueryRefs } from "@app/util/page";
import Select from "react-select";
import { AtomEffectParams } from "@app/util/recoil";
import { SignInCurrentUserFragment$key } from "@app/__generated__/SignInCurrentUserFragment.graphql";
import { SignIn_SignIn_Mutation } from "@app/__generated__/SignIn_SignIn_Mutation.graphql";
import { eff, fns, tsPattern, whenUnexpectedUnion } from "@shared-lib/src";
import graphql from "babel-plugin-relay/macro";
import {
	ChangeEventHandler,
	FormEventHandler,
	KeyboardEventHandler,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useFragment, useMutation, useQueryLoader } from "react-relay";
import { ActionMeta, OnChangeValue } from "react-select";
import { atom, selector, useRecoilState, useRecoilValue } from "recoil";
import * as styles from "./SignIn.css";
import { isSSR } from "@app/is-ssr";

export const tokenState = atom({
	key: "auth.token",
	default: {
		token: tokenRef.get,
		longTermSave: isSSR ? false : Boolean(localStorage.getItem(TOKEN_STORAGE_KEY)),
		shortTermSave: isSSR ? false : Boolean(sessionStorage.getItem(TOKEN_STORAGE_KEY)),
	},
	effects_UNSTABLE: [
		eff.reader.gen(function* (_) {
			const { onSet } = yield* _(
				eff.reader.environment<
					AtomEffectParams<{
						readonly token: string;
						readonly longTermSave: boolean;
						readonly shortTermSave: boolean;
					}>
				>(),
			);

			onSet((newValue) => {
				tokenRef.set(newValue.token);
				if (newValue.longTermSave) {
					localStorage.setItem(TOKEN_STORAGE_KEY, newValue.token);
				} else {
					localStorage.removeItem(TOKEN_STORAGE_KEY);
				}

				if (newValue.shortTermSave) {
					sessionStorage.setItem(TOKEN_STORAGE_KEY, newValue.token);
				} else {
					sessionStorage.removeItem(TOKEN_STORAGE_KEY);
				}
			});
		}),
	],
});

export const isAuthenticatedSelector = selector({
	key: "auth.isAuthenticated",
	get: ({ get }) => {
		const { token } = get(tokenState);

		return Boolean(token);
	},
});

enum RememberAuthPeriod {
	remember_until_ip_changes = "remember_until_ip_changes",
	remember_until_close_tab = "remember_until_close_tab",
	remember_until_close_browser = "remember_until_close_browser",
	remember_1_day = "remember_1_day",
	remember_1_week = "remember_1_week",
	remember_1_month = "remember_1_month",
	remember_3_months = "remember_3_months",
	remember_half_year = "remember_half_year",
	remember_year = "remember_year",
	remember_forever = "remember_forever",
}

const rememberPeriodLabelMap: {
	readonly [K in RememberAuthPeriod]: string;
} = {
	[RememberAuthPeriod.remember_until_ip_changes]: "Until my IP changes",
	[RememberAuthPeriod.remember_until_close_tab]: "Until I close this tab",
	[RememberAuthPeriod.remember_until_close_browser]: "Until I close browser",
	[RememberAuthPeriod.remember_1_day]: "1 day",
	[RememberAuthPeriod.remember_1_week]: "1 week",
	[RememberAuthPeriod.remember_1_month]: "1 month",
	[RememberAuthPeriod.remember_3_months]: "3 months",
	[RememberAuthPeriod.remember_half_year]: "Half year",
	[RememberAuthPeriod.remember_year]: "Year",
	[RememberAuthPeriod.remember_forever]: "Forever",
};

type RememberAuthPeriodOption = {
	readonly value: RememberAuthPeriod;
	readonly label: string;
};

const rememberPeriodOptions: readonly RememberAuthPeriodOption[] = eff.fn.pipe(
	Object.values(RememberAuthPeriod),
	eff.array.map((period) => ({
		value: period,
		label: rememberPeriodLabelMap[period],
	})),
);

const calcTokenLifeTime = eff.reader.gen(function* (_) {
	const now = new Date();

	const rememberPeriod = yield* _(eff.reader.environment<RememberAuthPeriod>());

	return tsPattern
		.match(rememberPeriod)
		.with(
			RememberAuthPeriod.remember_until_ip_changes,
			RememberAuthPeriod.remember_until_close_tab,
			RememberAuthPeriod.remember_until_close_browser,
			RememberAuthPeriod.remember_forever,
			() => 0,
		)
		.with(RememberAuthPeriod.remember_1_day, () =>
			eff.fn.pipe(now, fns.differenceInSeconds(eff.fn.pipe(now, fns.subDays(1)))),
		)
		.with(RememberAuthPeriod.remember_1_week, () =>
			eff.fn.pipe(now, fns.differenceInSeconds(eff.fn.pipe(now, fns.subWeeks(1)))),
		)
		.with(RememberAuthPeriod.remember_1_month, () =>
			eff.fn.pipe(now, fns.differenceInSeconds(eff.fn.pipe(now, fns.subMonths(1)))),
		)
		.with(RememberAuthPeriod.remember_3_months, () =>
			eff.fn.pipe(now, fns.differenceInSeconds(eff.fn.pipe(now, fns.subMonths(3)))),
		)
		.with(RememberAuthPeriod.remember_half_year, () =>
			eff.fn.pipe(now, fns.differenceInSeconds(eff.fn.pipe(now, fns.subMonths(6)))),
		)
		.with(RememberAuthPeriod.remember_year, () =>
			eff.fn.pipe(now, fns.differenceInSeconds(eff.fn.pipe(now, fns.subYears(1)))),
		)
		.exhaustive();
});

const signInCurrentUserFragment = graphql`
	fragment SignInCurrentUserFragment on AuthCurrentUserWithErrors {
		__typename
		... on AuthError {
			__typename
		}
		... on User {
			__typename
		}
	}
`;

export type SignInProps = {
	readonly currentUser: SignInCurrentUserFragment$key;
	readonly queryRefs: AppPageQueryRefs<"/sign-in">;
};

export const SignIn = (props: SignInProps) => {
	const [initialAppQueryRef] = props.queryRefs;
	const [, loadAppQuery] = useQueryLoader(appQuery, initialAppQueryRef);
	const [, setToken] = useRecoilState(tokenState);
	const goBack = useGoBack();
	const isAuthenticated = useRecoilValue(isAuthenticatedSelector);

	const currentUser = useFragment<SignInCurrentUserFragment$key>(
		signInCurrentUserFragment,
		props.currentUser,
	);

	useEffect(() => {
		const fiber = eff.fn.pipe(
			currentUser.__typename === "User",
			eff.bool.and(isAuthenticated),
			eff.bool.fold(
				() => eff.t.succeed(undefined),
				() =>
					eff.fn.pipe(
						eff.t.sleep(5),
						eff.t.chain(() =>
							eff.t.succeedWith(() => {
								goBack("/");
							}),
						),
					),
			),
			eff.t.runFiber,
		);

		return () => eff.fn.pipe(fiber, eff.fiber.interrupt, eff.t.run);
	}, [currentUser, goBack, isAuthenticated]);

	const [commitSignIn, isSignInInFlight] = useMutation<SignIn_SignIn_Mutation>(graphql`
		mutation SignIn_SignIn_Mutation(
			$username: String!
			$password: String!
			$restrictedByIP: Boolean!
			$lifeTime: Int!
		) {
			auth {
				logIn(
					username: $username
					password: $password
					restrictedByIP: $restrictedByIP
					lifeTime: $lifeTime
				) {
					__typename
					... on AuthLogin {
						token
					}
					... on AuthLoginErrors {
						general {
							error
						}
					}
				}
			}
		}
	`);

	const [signInResponse, setSignInResponse] =
		useState<SignIn_SignIn_Mutation["response"]["auth"]["logIn"]>();
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [rememberPeriod, setRememberPeriod] = useState<RememberAuthPeriodOption>({
		label: rememberPeriodLabelMap[RememberAuthPeriod.remember_until_ip_changes],
		value: RememberAuthPeriod.remember_until_ip_changes,
	});

	const signIn = useCallback(() => {
		// lifetime in seconds.
		// 0 - means there are no lifetime.
		const lifeTime = eff.fn.pipe(rememberPeriod.value, calcTokenLifeTime);

		const restrictedByIP =
			rememberPeriod.value === RememberAuthPeriod.remember_until_ip_changes;

		return commitSignIn({
			variables: {
				username,
				password,
				restrictedByIP,
				lifeTime,
			},
			onCompleted: (data) => {
				setSignInResponse(data.auth.logIn);
				if (data.auth.logIn.__typename === "AuthLogin") {
					setToken({
						token: data.auth.logIn.token,
						longTermSave:
							rememberPeriod.value !==
								RememberAuthPeriod.remember_until_close_tab &&
							rememberPeriod.value !==
								RememberAuthPeriod.remember_until_close_browser,
						shortTermSave:
							rememberPeriod.value ===
							RememberAuthPeriod.remember_until_close_browser,
					});
					loadAppQuery(initialAppQueryRef.variables, {
						fetchPolicy: "network-only",
					});
				}
			},
		});
	}, [
		commitSignIn,
		username,
		password,
		setToken,
		rememberPeriod,
		initialAppQueryRef.variables,
		loadAppQuery,
	]);

	const handleFormSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
		(event) => {
			event.preventDefault();
			signIn();
		},
		[signIn],
	);

	const handleFormKeyUp = useCallback<KeyboardEventHandler<HTMLFormElement>>(
		(event) => {
			if (event.key === "enter") {
				event.preventDefault();
				signIn();
			}
		},
		[signIn],
	);

	const onUsernameChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
		(event) => {
			setUsername(event.target.value);
		},
		[setUsername],
	);

	const onRememberPeriodChange = useCallback(
		(
			newValue: OnChangeValue<typeof rememberPeriod, false>,
			_actionMeta: ActionMeta<typeof rememberPeriod>,
		) => {
			if (newValue) {
				setRememberPeriod(newValue);
			}
		},
		[setRememberPeriod],
	);

	return (
		<div className={styles.container}>
			<form
				className={styles.form}
				onSubmit={handleFormSubmit}
				onKeyUp={handleFormKeyUp}
			>
				<label htmlFor="sign-in--username">Username:</label>
				<input
					id="sign-in--username"
					className={styles.usernameInput}
					type="text"
					placeholder="username"
					required
					value={username}
					onChange={onUsernameChange}
				/>
				<label htmlFor="sign-in--password">Password:</label>
				<input
					id="sign-in--password"
					className={styles.passwordInput}
					type="password"
					placeholder="password"
					required
					value={password}
					onChange={(event) => setPassword(event.target.value)}
				/>

				<label htmlFor="sign-in--remember-auth-select">
					I trust this computer:
				</label>

				<Select
					className={styles.rememberAuthSelect}
					defaultValue={rememberPeriod}
					options={rememberPeriodOptions}
					onChange={onRememberPeriodChange}
					styles={{
						container: (s) => ({ ...s, padding: "0" }),
						control: (s) => ({ ...s, width: "100%" }),
					}}
					inputId="sign-in--remember-auth-select"
				></Select>

				{signInResponse?.__typename === "AuthLoginErrors" &&
					(signInResponse.general || []).map(({ error }) =>
						tsPattern
							.match(error)
							.with(
								whenUnexpectedUnion<typeof error>(["WRONG_CREDENTIALS"]),
								() => (
									<div key="unexpected" className={styles.generalError}>
										Unexpected error
									</div>
								),
							)
							.with("WRONG_CREDENTIALS", (error) => (
								<div key={error} className={styles.generalError}>
									Wrong credentials.
								</div>
							))
							.exhaustive(),
					)}

				<div className={styles.submitBtnContainer}>
					<button className={styles.submitBtn} disabled={isSignInInFlight}>
						Sign In
					</button>
				</div>
			</form>
		</div>
	);
};
