/**
 * @generated SignedSource<<bca825f1f5814f0e1846058ede3ce095>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfileCurrentUserFragment$data = {
  readonly id: string;
  readonly username: string;
  readonly avatar: {
    readonly id: string;
    readonly path: string;
  } | null;
  readonly " $fragmentType": "UserProfileCurrentUserFragment";
};
export type UserProfileCurrentUserFragment$key = {
  readonly " $data"?: UserProfileCurrentUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileCurrentUserFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserProfileCurrentUserFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "avatar",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "path",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "169a03d64ca01136741c9de829fe9f69";

export default node;
