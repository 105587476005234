import { isSSR } from "@app/is-ssr";
import { Path, PathParams } from "@app/Router/Router";
import { useTypedNavigate } from "@app/Router/TypedNavigate";

const historyLength = () => (!isSSR && history ? history.length : 0);
const initialHistoryLength = historyLength();

export const useGoBack = () => {
	const navigate = useTypedNavigate();

	return <P extends Path>(
		fallbackPath: P,
		...params: PathParams<P> extends undefined
			? readonly []
			: readonly [PathParams<P>]
	) => {
		if (historyLength() > initialHistoryLength) {
			navigate(-1);
		} else {
			navigate(
				{ to: fallbackPath, params: params.length ? params[0] : undefined },
				{ replace: true },
			);
		}
	};
};
