import { appQuery } from "@app/App";
import { useGoBack } from "@app/History/useGoBack";
import { useTypedNavigate } from "@app/Router/TypedNavigate";
import { resolveFilePath } from "@app/util/file-storage";
import { AppPageQueryRefs } from "@app/util/page";
import { registerWithRef } from "@app/util/react-hook-form";
import { useAtomicRef } from "@app/util/use-atomic-ref";
import { UserProfileCurrentUserFragment$key } from "@app/__generated__/UserProfileCurrentUserFragment.graphql";
import { UserProfileEditUserMutation } from "@app/__generated__/UserProfileEditUserMutation.graphql";
import { UserProfileUploadFileMutation } from "@app/__generated__/UserProfileUploadFileMutation.graphql";
import { eff, tsPattern, whenNonEmptyArray, whenUnexpectedUnion } from "@shared-lib/src";
import graphql from "babel-plugin-relay/macro";
import {
	ChangeEventHandler,
	MouseEventHandler,
	useCallback,
	useEffect,
	useState,
} from "react";
import { useForm } from "react-hook-form";
import { useFragment, useMutation, useQueryLoader } from "react-relay";
import * as styles from "./UserProfile.css";

export type UserProfileProps = {
	readonly currentUser: UserProfileCurrentUserFragment$key;
	readonly queryRefs: AppPageQueryRefs<"/profile">;
};

type UserProfileForm = {
	readonly username: string;
	readonly avatarFile: FileList;
	readonly password: string;
};

export const UserProfile = (props: UserProfileProps) => {
	const [initialAppQueryRef] = props.queryRefs;
	const [, loadAppQuery] = useQueryLoader(appQuery, initialAppQueryRef);
	const goBack = useGoBack();

	const currentUser = useFragment<UserProfileCurrentUserFragment$key>(
		graphql`
			fragment UserProfileCurrentUserFragment on User {
				id
				username
				avatar {
					id
					path
				}
			}
		`,
		props.currentUser,
	);

	const [uploadFile, isUploadFileInFlight] =
		useMutation<UserProfileUploadFileMutation>(graphql`
			mutation UserProfileUploadFileMutation($file: FileStorageUpload!) {
				fileStorage {
					upload(file: $file) {
						__typename
						... on File {
							id
							path
						}
						... on FileStorageUploadErrors {
							general {
								error
							}
						}
					}
				}
			}
		`);

	const [editUser, isEditUserInFlight] =
		useMutation<UserProfileEditUserMutation>(graphql`
			mutation UserProfileEditUserMutation($input: UserEditInput!) {
				user {
					edit(input: $input) {
						__typename
						... on UserEditErrors {
							general {
								error
							}
							username {
								error
							}
							avatarId {
								error
							}
						}
						... on User {
							__typename
						}
					}
				}
			}
		`);

	const [editUserResponse, setEditUserResponse] =
		useState<UserProfileEditUserMutation["response"]["user"]["edit"]>();

	const [avatarImage, setAvatarImage] = useState(
		currentUser.avatar
			? {
					URL: resolveFilePath(currentUser.avatar.path),
					id: currentUser.avatar.id,
			  }
			: undefined,
	);

	const [uploadFileResponse, setUploadFileResponse] =
		useState<UserProfileUploadFileMutation["response"]["fileStorage"]["upload"]>();

	useEffect(() => {
		if (uploadFileResponse && uploadFileResponse.__typename === "File") {
			setAvatarImage({
				URL: resolveFilePath(uploadFileResponse.path),
				id: uploadFileResponse.id,
			});
		}
	}, [uploadFileResponse, setAvatarImage]);

	const form = useForm<UserProfileForm>({
		defaultValues: {
			username: currentUser.username,
			password: "",
		},
	});

	const navigate = useTypedNavigate();

	const onSubmit = useCallback(
		(data: UserProfileForm) => {
			return editUser({
				variables: {
					input: {
						id: currentUser.id,
						avatarId: avatarImage?.id || null,
						username: data.username,
						password: data.password || undefined,
					},
				},
				onCompleted: (data) => {
					loadAppQuery(initialAppQueryRef.variables, {
						fetchPolicy: "network-only",
					});
					setEditUserResponse(data.user.edit);

					if (data.user.edit.__typename === "User") {
						navigate({ to: "/" });
					}
				},
			});
		},
		[
			currentUser,
			avatarImage,
			editUser,
			setEditUserResponse,
			navigate,
			loadAppQuery,
			initialAppQueryRef.variables,
		],
	);

	const onAvatarInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
		(event) => {
			if (!event.target.files || !event.target.files[0]) {
				return;
			}

			const file = event.target.files[0];

			return uploadFile({
				variables: {
					file,
				},
				onCompleted: ({ fileStorage: { upload } }) => {
					setUploadFileResponse(upload);
				},
			}).dispose;
		},
		[uploadFile, setUploadFileResponse],
	);

	const avatarInputRef = useAtomicRef<HTMLInputElement | null>(null);

	const onAvatarUploadBtnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(event) => {
			event.preventDefault();
			if (avatarInputRef.get) {
				avatarInputRef.get.click();
			}
		},
		[avatarInputRef],
	);

	const onAvatarResetBtnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(event) => {
			event.preventDefault();
			setAvatarImage(
				currentUser.avatar
					? {
							URL: resolveFilePath(currentUser.avatar.path),
							id: currentUser.avatar.id,
					  }
					: undefined,
			);
		},
		[currentUser, setAvatarImage],
	);

	const onAvatarRemoveBtnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(event) => {
			event.preventDefault();
			setAvatarImage(undefined);
		},
		[setAvatarImage],
	);

	const onBackBtnClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
		(_event) => {
			goBack("/");
		},
		[goBack],
	);

	return (
		<div className={styles.container}>
			<form className={styles.form} onSubmit={form.handleSubmit(onSubmit)}>
				<label htmlFor="user-profile--username" className={styles.usernameLabel}>
					Username
				</label>
				<input
					{...form.register("username")}
					id="user-profile--username"
					className={styles.usernameInput}
					type="text"
					placeholder="username"
					required
				/>

				{tsPattern
					.match(editUserResponse)
					.with(
						{
							__typename: "UserEditErrors",
							username: whenNonEmptyArray(),
						},
						({ username }) =>
							username.map(({ error }) =>
								tsPattern
									.match(error)
									.with(
										whenUnexpectedUnion<typeof error>([
											"ALREADY_IN_USE",
										]),
										(error) => (
											<div key={error} className={styles.error}>
												Something went wrong
											</div>
										),
									)
									.with("ALREADY_IN_USE", (error) => (
										<div key={error} className={styles.error}>
											Username already in use
										</div>
									))
									.exhaustive(),
							),
					)
					.otherwise(eff.fn.constNull)}

				<label htmlFor="user-profile--password" className={styles.passwordLabel}>
					Password
				</label>
				<input
					{...form.register("password")}
					id="user-profile--password"
					className={styles.passwordInput}
					type="password"
					placeholder="passsword"
				/>

				<input
					{...registerWithRef(
						avatarInputRef,
						form.register("avatarFile", { onChange: onAvatarInputChange }),
					)}
					className={styles.avatarFileInput}
					type="file"
					accept=".jpg, .jpeg, .png"
					id="avatarFile"
				/>

				<div className={styles.avatarBtnContainer}>
					<button
						className={styles.avatarUploadBtn}
						disabled={isUploadFileInFlight}
						onClick={onAvatarUploadBtnClick}
						type="button"
					>
						Upload Avatar
					</button>
					{currentUser.avatar && currentUser.avatar.id !== avatarImage?.id && (
						<button
							className={styles.avatarResetBtn}
							onClick={onAvatarResetBtnClick}
							disabled={isUploadFileInFlight}
							type="button"
						>
							Reset
						</button>
					)}
					{avatarImage && (
						<button
							className={styles.avatarRemoveBtn}
							onClick={onAvatarRemoveBtnClick}
							disabled={isUploadFileInFlight}
							type="button"
						>
							Remove
						</button>
					)}
				</div>

				{tsPattern
					.match(editUserResponse)
					.with(
						{
							__typename: "UserEditErrors",
							avatarId: whenNonEmptyArray(),
						},
						({ avatarId }) =>
							avatarId.map(({ error }) =>
								tsPattern
									.match(error)
									.with(
										"NOT_FOUND",
										whenUnexpectedUnion<typeof error>(["NOT_FOUND"]),
										(error) => (
											<div key={error} className={styles.error}>
												Avatar not found
											</div>
										),
									)
									.exhaustive(),
							),
					)
					.otherwise(eff.fn.constNull)}

				{tsPattern
					.match(uploadFileResponse)
					.with(
						{
							__typename: "FileStorageUploadErrors",
							general: whenNonEmptyArray(),
						},
						({ general }) =>
							general.map(({ error }) =>
								tsPattern
									.match(error)
									.with(
										"INTERNAL_ERROR",
										whenUnexpectedUnion<typeof error>([
											"INTERNAL_ERROR",
										]),
										(error) => (
											<div key={error} className={styles.error}>
												Wrong credentials.
											</div>
										),
									)
									.exhaustive(),
							),
					)
					.otherwise(eff.fn.constNull)}

				<div className={styles.avatarImageContainer}>
					{eff.fn.pipe(
						isUploadFileInFlight,
						eff.bool.fold(
							() =>
								eff.fn.pipe(
									avatarImage,
									eff.option.fromNullable,
									eff.option.fold(
										() => <div>No image</div>,
										(avatarImage) => (
											<img
												src={avatarImage.URL}
												className={styles.avatarImage}
												alt="Avatar"
											/>
										),
									),
								),
							() => <div>Uploading...</div>,
						),
					)}
				</div>

				{tsPattern
					.match(editUserResponse)
					.with(
						{
							__typename: "UserEditErrors",
							general: whenNonEmptyArray(),
						},
						({ general }) =>
							general.map(({ error }) =>
								tsPattern
									.match(error)
									.with(
										whenUnexpectedUnion<typeof error>([
											"ACCESS_DENIED",
										]),
										(error) => (
											<div key={error} className={styles.error}>
												Something went wrong
											</div>
										),
									)
									.with("ACCESS_DENIED", (error) => (
										<div key={error} className={styles.error}>
											Access denied
										</div>
									))
									.exhaustive(),
							),
					)
					.otherwise(eff.fn.constNull)}

				<div className={styles.actionsContainer}>
					<button
						className={styles.backBtn}
						type="button"
						onClick={onBackBtnClick}
					>
						Back
					</button>
					<button
						className={styles.submitBtn}
						disabled={isUploadFileInFlight || isEditUserInFlight}
					>
						Save
					</button>
				</div>
			</form>
		</div>
	);
};
