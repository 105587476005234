/**
 * @generated SignedSource<<dfd227c8636811ec2835ccbd07e5a6c0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AuthErrorType = "NOT_AUTHENTICATED" | "%future added value";
export type AppQuery$variables = {};
export type AppQuery$data = {
  readonly auth: {
    readonly currentUser: {
      readonly __typename: string;
      readonly error?: AuthErrorType;
      readonly " $fragmentSpreads": FragmentRefs<"AppPageCurrentUserFragment" | "SignInCurrentUserFragment">;
    };
  };
};
export type AppQuery = {
  variables: AppQuery$variables;
  response: AppQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "error",
      "storageKey": null
    }
  ],
  "type": "AuthError",
  "abstractKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AppQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthQuery",
        "kind": "LinkedField",
        "name": "auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AppPageCurrentUserFragment"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SignInCurrentUserFragment"
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AppQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthQuery",
        "kind": "LinkedField",
        "name": "auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isAuthCurrentUserWithErrors"
              },
              (v1/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "username",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "File",
                    "kind": "LinkedField",
                    "name": "avatar",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "path",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "User",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38a89395450a2b643766d4ea6f5592f1",
    "id": null,
    "metadata": {},
    "name": "AppQuery",
    "operationKind": "query",
    "text": "query AppQuery {\n  auth {\n    currentUser {\n      __typename\n      ...AppPageCurrentUserFragment\n      ...SignInCurrentUserFragment\n      ... on AuthError {\n        error\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment AppPageCurrentUserFragment on AuthCurrentUserWithErrors {\n  __isAuthCurrentUserWithErrors: __typename\n  __typename\n  ... on AuthError {\n    __typename\n  }\n  ... on User {\n    __typename\n  }\n  ...UserProfileCurrentUserFragment\n}\n\nfragment SignInCurrentUserFragment on AuthCurrentUserWithErrors {\n  __isAuthCurrentUserWithErrors: __typename\n  __typename\n  ... on AuthError {\n    __typename\n  }\n  ... on User {\n    __typename\n  }\n}\n\nfragment UserProfileCurrentUserFragment on User {\n  id\n  username\n  avatar {\n    id\n    path\n  }\n}\n"
  }
};
})();

(node as any).hash = "139934e97cd988033c349b8875849c95";

export default node;
