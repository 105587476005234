import { NonEmptyArray, pipe } from "@effect-ts/core";
import * as Prelude from "@effect-ts/core/Prelude";
import { UnsafeMutableTuple } from "../unsafe-mutable-tuple";
import { ValidationURI } from "./core";
import type { V } from "./definition";
import * as Op from "./operations";

export const Any = Prelude.instance<
  Prelude.Any<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  any: () => Op.succeed({}),
});

export const AssociativeBoth = Prelude.instance<
  Prelude.AssociativeBoth<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  both: Op.zip,
});

export const AssociativeEither = Prelude.instance<
  Prelude.AssociativeEither<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  orElseEither: Op.orElseEither,
});

export const AssociativeFlatten = Prelude.instance<
  Prelude.AssociativeFlatten<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  flatten: Op.flatten,
});

export const Covariant = Prelude.instance<
  Prelude.Covariant<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  map: Op.map,
});

export const Applicative = Prelude.instance<
  Prelude.Applicative<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  ...Any,
  ...Covariant,
  ...AssociativeBoth,
});

export const Monad = Prelude.instance<
  Prelude.Monad<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  ...Any,
  ...Covariant,
  ...AssociativeFlatten,
});

export const Fail = Prelude.instance<
  Prelude.FX.Fail<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  fail: (e) => Op.refute(e as unknown as NonEmptyArray.NonEmptyArray<unknown>),
});

export const Run = Prelude.instance<
  Prelude.FX.Run<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  either: (fa) => pipe(fa, Op.toEither, Op.succeed),
});

export const ForEach = Prelude.instance<
  Prelude.ForEach<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  map: Op.map,
  forEachF: Op.forEachF,
});

export const FoldMap = Prelude.instance<
  Prelude.FoldMap<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  foldMap: Op.foldMap,
});

export const Reduce = Prelude.instance<
  Prelude.Reduce<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  reduce: Op.reduce,
});

export const ReduceRight = Prelude.instance<
  Prelude.ReduceRight<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  reduceRight: Op.reduceRight,
});

export const Foldable = Prelude.instance<
  Prelude.Foldable<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>, V>
>({
  ...FoldMap,
  ...Reduce,
  ...ReduceRight,
});

export const ChainRec = Prelude.instance<
  Prelude.ChainRec<UnsafeMutableTuple<readonly [Prelude.URI<ValidationURI>]>>
>({
  chainRec: Op.chainRec,
});
