import React from "react";
import { LOCALES } from "../i18n/locales";
import { Helmet } from "react-helmet";

type MetaDecorator = {
	readonly local: LOCALES;
};

const MetaInfo: React.FC<MetaDecorator> = ({ local }: MetaDecorator) => {
	const isFr = local === "fr";
	const url = "https://promo.edialux.amphion.be";

	const title = isFr
		? "Gagnez un entretien gratuit de votre jardin par nos professionnels"
		: "Win een gratis tuinonderhoud door onze professionals";
	const description = isFr
		? "Toujours un temps d’avance avec edialux"
		: "Altijd een stapje voor met edialux";
	return (
		<Helmet>
			<title>{title}</title>
			<meta name="description" content={description} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			{/* <meta property="og:image" content={ %PUBLIC_URL% + '/socials/og_image-fr.jpg'}/> */}
			<meta property="og:image" content={`${url}/socials/og_image-${local}.jpg`} />
			<meta property="og:type" content="article" />
			<meta property="og:url" content={url} />
			<meta name="twitter:card" content="" />
			<meta name="twitter:site" content="" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{/* <meta name="twitter:image" content="/socials/twittercard_image-fr.jpg" /> */}
			<meta
				name="twitter:image"
				content={`${url}/socials/twittercard_image-${local}.jpg`}
			/>
			{console.log("rebuild")}
		</Helmet>
	);
};

export default MetaInfo;
