/**
 * @generated SignedSource<<55a9fda99d7f04b9f77eea6fa8dc1b16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppPageCurrentUserFragment$data = {
  readonly __typename: string;
  readonly " $fragmentSpreads": FragmentRefs<"UserProfileCurrentUserFragment">;
  readonly " $fragmentType": "AppPageCurrentUserFragment";
};
export type AppPageCurrentUserFragment$key = {
  readonly " $data"?: AppPageCurrentUserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppPageCurrentUserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppPageCurrentUserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserProfileCurrentUserFragment"
    }
  ],
  "type": "AuthCurrentUserWithErrors",
  "abstractKey": "__isAuthCurrentUserWithErrors"
};

(node as any).hash = "707981c3c28364b563e28e3cc5a9802c";

export default node;
