import "frontend/src/UserProfile/UserProfile.css.ts.vanilla.css!=!../../node_modules/virtual-resource-loader/dist/virtual-resource-loader.cjs.js?{\"source\":\"Ll80Z21hOGkwIHsKICBkaXNwbGF5OiBmbGV4OwogIGp1c3RpZnktY29udGVudDogY2VudGVyOwogIHBhZGRpbmctdG9wOiAzMHB4Owp9Ci5fNGdtYThpMSB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLl80Z21hOGkyIHsKICBmb250LXNpemU6IDIwcHg7CiAgcGFkZGluZzogOHB4OwogIGxpbmUtaGVpZ2h0OiAyMHB4Owp9Ci5fNGdtYThpMyB7CiAgbWFyZ2luLWJvdHRvbTogMTVweDsKfQouXzRnbWE4aTQgewogIGFsaWduLXNlbGY6IHN0YXJ0Owp9Ci5fNGdtYThpNSB7CiAgZGlzcGxheTogbm9uZTsKfQouXzRnbWE4aWIgewogIGRpc3BsYXk6IGZsZXg7CiAganVzdGlmeS1jb250ZW50OiBzcGFjZS1hcm91bmQ7CiAgbWFyZ2luLWJvdHRvbTogMTVweDsKfQouXzRnbWE4aWMgewogIG1hcmdpbi1yaWdodDogNXB4OwogIG1hcmdpbi1sZWZ0OiA1cHg7Cn0KLl80Z21hOGlkIHsKICBtYXJnaW4tcmlnaHQ6IDVweDsKICBtYXJnaW4tbGVmdDogNXB4Owp9Ci5fNGdtYThpZSB7CiAgbWFyZ2luLXJpZ2h0OiA1cHg7CiAgbWFyZ2luLWxlZnQ6IDVweDsKfQouXzRnbWE4aWYgewogIGRpc3BsYXk6IGZsZXg7CiAgd2lkdGg6IDE1MHB4OwogIGhlaWdodDogMTUwcHg7CiAgbWFyZ2luLWJvdHRvbTogMTVweDsKICBib3JkZXI6IDFweCBkb3R0ZWQgZ3JheTsKICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjsKICBhbGlnbi1pdGVtczogY2VudGVyOwp9Ci5fNGdtYThpZyB7CiAgd2lkdGg6IDE1MHB4OwogIGhlaWdodDogMTUwcHg7CiAgb2JqZWN0LWZpdDogY292ZXI7Cn0KLl80Z21hOGloIHsKICBkaXNwbGF5OiBmbGV4OwogIGp1c3RpZnktY29udGVudDogc3BhY2UtYmV0d2VlbjsKICB3aWR0aDogMTAwJTsKfQouXzRnbWE4aWsgewogIG1hcmdpbi1ib3R0b206IDE1cHg7CiAgY29sb3I6IHJlZDsKfQ==\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var actionsContainer = '_4gma8ih';
export var avatarBtnContainer = '_4gma8ib';
export var avatarFileInput = '_4gma8i3 _4gma8i2 _4gma8i5';
export var avatarImage = '_4gma8ig';
export var avatarImageContainer = '_4gma8if';
export var avatarRemoveBtn = '_4gma8ie';
export var avatarResetBtn = '_4gma8id';
export var avatarUploadBtn = '_4gma8ic';
export var backBtn = '_4gma8i2';
export var container = '_4gma8i0';
export var error = '_4gma8ik';
export var form = '_4gma8i1';
export var hide = '_4gma8i5';
export var passwordInput = '_4gma8i3 _4gma8i2';
export var passwordLabel = '_4gma8i4';
export var submitBtn = '_4gma8i2';
export var usernameInput = '_4gma8i3 _4gma8i2';
export var usernameLabel = '_4gma8i4';