import { SomethingWentWrong } from "@app/SomethingWentWrong/SomethingWentWrong";
import React from "react";

export class GeneralErrorBoundary extends React.PureComponent<
	Record<string, unknown>,
	{ readonly error?: Error }
> {
	constructor(props: Record<string, unknown>) {
		super(props);
		this.state = { error: undefined };
	}

	static getDerivedStateFromError(error?: Error) {
		return { error };
	}

	render() {
		if (this.state.error) {
			return <SomethingWentWrong error={this.state.error} />;
		}

		return this.props.children;
	}
}
