import { Link as OriginalLink, LinkProps } from "react-router-dom";
import { mkPath, Path, PathParams } from "./Router";

export type TypedLinkProps<P extends Path> = Omit<LinkProps, "to"> & {
	readonly to: P;
} & (PathParams<P> extends undefined
		? {
				readonly params?: PathParams<P>;
		  }
		: {
				readonly params: PathParams<P>;
		  });

/**
 * Type safe version of `react-router-dom/Link`
 */
export const TypedLink = <P extends Path>(props: TypedLinkProps<P>) => {
	return (
		<OriginalLink
			{...(props as Omit<TypedLinkProps<P>, "params">)}
			to={mkPath({ to: props.to, params: props.params })}
		/>
	);
};

export const Link = TypedLink;
