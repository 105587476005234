/**
 * @generated SignedSource<<64d82166db6bdc1b73aea68b984767e9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Question1 = "DELETE_SPRAY" | "HERBI_ALARM_SPRAY" | "%future added value";
export type Question2 = "MIEREN" | "WESPEN" | "%future added value";
export type AddAnswerInput = {
  email: string;
  hiftingDemand: number;
  id: string;
  language: string;
  name: string;
  newsletter: boolean;
  phone: any;
  privacy: boolean;
  question1: Question1;
  question2: Question2;
};
export type Form_Add_Answer_Mutation$variables = {
  input: AddAnswerInput;
};
export type Form_Add_Answer_Mutation$data = {
  readonly answer: {
    readonly add: {
      readonly __typename: string;
    };
  };
};
export type Form_Add_Answer_Mutation = {
  variables: Form_Add_Answer_Mutation$variables;
  response: Form_Add_Answer_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Form_Add_Answer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AnswerMutation",
        "kind": "LinkedField",
        "name": "answer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Answer",
            "kind": "LinkedField",
            "name": "add",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Form_Add_Answer_Mutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AnswerMutation",
        "kind": "LinkedField",
        "name": "answer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Answer",
            "kind": "LinkedField",
            "name": "add",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "08914f39ce152e1da56c25eeb4290f9c",
    "id": null,
    "metadata": {},
    "name": "Form_Add_Answer_Mutation",
    "operationKind": "mutation",
    "text": "mutation Form_Add_Answer_Mutation(\n  $input: AddAnswerInput!\n) {\n  answer {\n    add(input: $input) {\n      __typename\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8fd8cf25abe7e7bef2d15b5c1a5253d4";

export default node;
