import { ImgWebpProps } from "../ImgWebp/imgType";

import main from "../images/intro-bg@_landscape.jpg";
import main2x from "../images/intro-bg@_landscape@2x.jpg";
import webp from "../images/intro-bg@_landscape.webp";
import webp2x from "../images/intro-bg@_landscape@2x.webp";
import portraitX1 from "../images/intro-bg@_portrait.jpg";
import portraitX2 from "../images/intro-bg@_portrait@2x.jpg";
import portraitX1Webp from "../images/intro-bg@_portrait.webp";
import portraitX2Webp from "../images/intro-bg@_portrait@2x.webp";

import decorLb from "../images/decor/ladybug.png";
import decorLb2x from "../images/decor/ladybug@2x.png";
import decorLbWebp from "../images/decor/ladybug.webp";
import decorLbWebp2x from "../images/decor/ladybug@2x.webp";

import decorPapillon from "../images/decor/papillon.png";
import decorPapillon2x from "../images/decor/papillon@2x.png";
import decorPapillonWebp from "../images/decor/papillon.webp";
import decorPapillonWebp2x from "../images/decor/papillon@2x.webp";

import decorStepsBtn from "../images/decor/steps-decor.png";
import decorStepsBtn2x from "../images/decor/steps-decor@2x.png";
import decorStepsBtnWebp from "../images/decor/steps-decor.webp";
import decorStepsBtnWebp2x from "../images/decor/steps-decor@2x.webp";

export const picture_config: ImgWebpProps = {
	src: {
		main: main,
		main2x: main2x,
		webp: webp,
		webp2x: webp2x,
		portraitX1: portraitX1,
		portraitX2: portraitX2,
		portraitX1Webp: portraitX1Webp,
		portraitX2Webp: portraitX2Webp,
	},
	orientationChange: true,
	alt: "",
	sizes: {
		w: 1922,
		h: 925,
		wPortrait: 375,
		hPortrait: 667,
	},
	imgClassName: "introImg",
	picClassName: "introPic",
};

export const decor_ladybug: ImgWebpProps = {
	src: {
		main: decorLb,
		main2x: decorLb2x,
		webp: decorLbWebp,
		webp2x: decorLbWebp2x,
	},
	alt: "",
	sizes: {
		w: 135,
		h: 149,
	},
	imgClassName: "decor-ladybug",
};

export const decor_papillon: ImgWebpProps = {
	src: {
		main: decorPapillon,
		main2x: decorPapillon2x,
		webp: decorPapillonWebp,
		webp2x: decorPapillonWebp2x,
	},
	alt: "",
	sizes: {
		w: 754,
		h: 765,
	},
	imgClassName: "decor-papillon",
};

export const decor_btn: ImgWebpProps = {
	src: {
		main: decorStepsBtn,
		main2x: decorStepsBtn2x,
		webp: decorStepsBtnWebp,
		webp2x: decorStepsBtnWebp2x,
	},
	alt: "",
	sizes: {
		w: 782,
		h: 292,
	},
	imgClassName: "decor-steps-btn",
};
