import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Link, useMatch, useResolvedPath, useSearchParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { LOCALES } from "../i18n/locales";
import type { LinkProps } from "react-router-dom";
import logo from "./logo.svg";
import Select, { OnChangeValue, ActionMeta } from "react-select";
import MetaInfo from "../MetaInfo/MetaInfo";
import HeadScripts from "../HeadScripts/HeadScripts";
import { isSSR } from "@app/is-ssr";

type HeaderProps = {
	readonly setCurrentLocal: (value: LOCALES) => void;
	readonly currentLocale: LOCALES;
};

type LanguageOption = {
	readonly label: string;
	readonly value: LOCALES;
};

const Header: React.FC<HeaderProps> = ({
	setCurrentLocal,
	currentLocale,
}: HeaderProps) => {
	const [isMobile, setIsMobile] = useState(false);
	const [, setSearchParams] = useSearchParams();

	useEffect(() => {
		if (isSSR) {
			return;
		}

		const onResize = () => {
			setIsMobile(window.matchMedia("(max-width: 767px)").matches);
		};

		window.addEventListener("resize", onResize);

		return () => {
			window.removeEventListener("resize", onResize);
		};
	}, [setIsMobile]);

	//header change on scroll
	const [small, setSmall] = useState(false);
	useEffect(() => {
		if (isSSR) {
			return;
		}

		const onScroll = () => setSmall(window.pageYOffset > 100);

		window.addEventListener("scroll", onScroll);

		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, []);

	//header open mob menu
	const [visibleHeaderNav, setVisibleHeaderNav] = useState(false);
	useEffect(() => {
		document.body.classList.toggle("menu-open", visibleHeaderNav);
	}, [visibleHeaderNav]);

	const languages: readonly LanguageOption[] = [
		{
			label: "NEDERLANDS",
			value: LOCALES.DUTCH,
		},
		{
			label: "FRANÇAIS",
			value: LOCALES.FRENCH,
		},
	];

	// const changeMetaAndHtmlLang = (lang: LOCALES): void => {
	// 	let changeToLang = "";
	// 	if (lang === "fr-FR") {
	// 		changeToLang = "fr";
	// 	} else {
	// 		changeToLang = "nl";
	// 	}
	// 	document.documentElement.lang = changeToLang;
	// };

	const changeLocal = useCallback<
		(
			newValue: OnChangeValue<LanguageOption, false>,
			actionMeta: ActionMeta<LanguageOption>,
		) => void
	>(
		(option) => {
			if (option) {
				setCurrentLocal(option.value);
				setSearchParams({ lang: option.value });
			}
		},
		[setCurrentLocal, setSearchParams],
	);

	const changeNavVisibility = () => {
		if (isMobile) {
			setVisibleHeaderNav(!visibleHeaderNav);
		}
	};

	const CustomLink = ({ children, to, ...props }: LinkProps) => {
		const resolved = useResolvedPath(to);
		const match = useMatch({ path: resolved.pathname, end: true });

		return (
			<Link
				className={match ? "is-active" : ""}
				to={to}
				{...props}
				onClick={changeNavVisibility}
			>
				{children}
			</Link>

			// <div>
			//     <Link
			//         style={{ textDecoration: match ? "underline" : "none" }}
			//         to={to}
			//         {...props}
			//     >
			//         {children}
			//     </Link>
			//     {match && " (active)"}
			// </div>
		);
	};

	return (
		<header id="header" className={`header ${small ? "header_smaller" : ""}`}>
			<MetaInfo local={currentLocale} />
			<HeadScripts />

			<div className="header__container">
				<div className="header__left">
					<Link to="/" className="header__logo__link">
						<img src={logo} alt="" className="header__logo" />
					</Link>
				</div>
				<div className="header__mob-menu">
					<div className="header__center">
						<nav className="header__nav nav">
							<ul className="nav__list">
								<li>
									<CustomLink to="/">
										<FormattedMessage id="mainPage"></FormattedMessage>
									</CustomLink>
								</li>
								<li>
									<CustomLink to="/form">
										<FormattedMessage id="winPage"></FormattedMessage>
									</CustomLink>
								</li>
							</ul>
						</nav>
					</div>
					<div className="header__right">
						<div className="switcher">
							<Suspense fallback={"Loading..."}>
								<Select
									id="header-lang-switcher"
									instanceId="header-lang-switcher-instance"
									className="react-select-container"
									classNamePrefix="react-select"
									options={languages}
									defaultValue={languages.find(
										(e) => e.value === currentLocale,
									)}
									onChange={changeLocal}
								></Select>
							</Suspense>
						</div>
					</div>
				</div>
				<div className="header__trigger" onClick={changeNavVisibility}>
					<span></span>
				</div>
			</div>
		</header>
	);
};

export default Header;
