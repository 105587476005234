import Form from "@app/Form/Form";
import { isSSR } from "@app/is-ssr";
import MainPage from "@app/MainPage/MainPage";
import CompetitionRules from "@app/CompetitionRules/CompetitionRules";
import PrivacyPolicy from "@app/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "@app/CookiePolicy/CookiePolicy";
import SalesConditions from "@app/SalesConditions/SalesConditions";
import { NotAuthenticated } from "@app/NotAuthenticated/NotAuthenticated";
import { isAuthenticatedSelector, SignIn } from "@app/SignIn/SignIn";
import { SignOut } from "@app/SignOut/SignOut";
import { UserProfile } from "@app/UserProfile/UserProfile";
import {
	AppPageProps,
	AppPageQueries,
	NonProtectedPages,
	ProtectedPages,
} from "@app/util/page";
import { useSyncAtomicRef } from "@app/util/use-sync-atomic-ref";
import { AppPageCurrentUserFragment$key } from "@app/__generated__/AppPageCurrentUserFragment.graphql";
import {
	eff,
	ExactEmptyObject,
	OmitWithValue,
	PartialWithValue,
	tsPattern,
} from "@shared-lib/src";
import graphql from "babel-plugin-relay/macro";
import { useEffect, useMemo, useState } from "react";
import { loadQuery, PreloadedQuery, useFragment, useRelayEnvironment } from "react-relay";
import { UseQueryLoaderLoadQueryOptions } from "react-relay/relay-hooks/useQueryLoader";
import { useParams, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { GraphQLTaggedNode, OperationType, Variables } from "relay-runtime";
import { appProtectedPaths, Path } from "./Router";

type MkVariables<TQuery extends OperationType> =
	| (ExactEmptyObject extends TQuery["variables"] ? undefined : never)
	| TQuery["variables"]
	| ((props: {
			readonly params: ReturnType<typeof useParams>;
			readonly searchParams: URLSearchParams;
	  }) => TQuery["variables"] | Promise<TQuery["variables"]>);

type QueryOptions<QueryType extends OperationType> = {
	readonly query: eff.branded.Branded<GraphQLTaggedNode, QueryType>;
	readonly relayOptions?: UseQueryLoaderLoadQueryOptions;
} & PartialWithValue<
	{
		readonly mkVariables: MkVariables<QueryType>;
	},
	undefined
>;

type QueryOptionsListToRefs<
	QueryOpts extends readonly QueryOptions<OperationType>[],
	Acc extends readonly PreloadedQuery<OperationType>[] = readonly [],
> = QueryOpts extends readonly [QueryOptions<infer Head>, ...infer Tail]
	? QueryOptionsListToRefs<
			Tail extends readonly QueryOptions<OperationType>[] ? Tail : readonly [],
			readonly [PreloadedQuery<Head>, ...Acc]
	  >
	: Acc;

type QueriesWithOptions<
	Queries extends readonly OperationType[],
	Acc extends readonly QueryOptions<OperationType>[] = readonly [],
> = Queries extends readonly [infer Head, ...infer Tail]
	? QueriesWithOptions<
			Tail extends readonly OperationType[] ? Tail : readonly [],
			Head extends OperationType ? readonly [...Acc, QueryOptions<Head>] : Acc
	  >
	: Acc;

export type PathWithOptions<P extends Path> = {
	readonly [K in Path]: {
		readonly path: K;
	} & AppPageProps<K>;
}[P];

type AppPageProps_<P extends Path> = {
	readonly pageProps: {
		readonly path: P;
		readonly fallback?: JSX.Element;
		readonly currentUser: AppPageCurrentUserFragment$key;
	} & OmitWithValue<
		{
			readonly queries: QueriesWithOptions<AppPageQueries<P>>;
		},
		readonly []
	>;
} & Omit<AppPageProps<P>, "queryRefs">;

const splitOptions = (
	options: PathWithOptions<Path>,
): eff.either.Either<
	PathWithOptions<NonProtectedPages>,
	PathWithOptions<ProtectedPages>
> =>
	eff.fn.pipe(
		appProtectedPaths.includes(options.path as ProtectedPages),
		eff.bool.fold(
			() =>
				eff.either.left(options as unknown as PathWithOptions<NonProtectedPages>),
			() => eff.either.right(options as unknown as PathWithOptions<ProtectedPages>),
		),
	);

export const AppPage = <P extends Path>(appPageProps: AppPageProps_<P>) => {
	const relayEnvironment = useRelayEnvironment();
	const paramsRef = useSyncAtomicRef(useParams());
	const [searchParams] = useSearchParams();
	const searchParamsRef = useSyncAtomicRef(searchParams);
	const _queries = (
		appPageProps.pageProps as {
			readonly queries?: QueriesWithOptions<AppPageQueries<P>>;
		}
	).queries;
	const queries = useMemo(
		() => (_queries || []) as readonly QueryOptions<OperationType>[],
		[_queries],
	);
	const [queryRefs, setQueryRefs] = useState<{
		readonly path?: Path;
		readonly refs: QueryOptionsListToRefs<QueriesWithOptions<AppPageQueries<P>>>;
	}>({
		path: undefined,
		refs: [] as unknown as QueryOptionsListToRefs<
			QueriesWithOptions<AppPageQueries<P>>
		>,
	});
	const isAuthenticated = useRecoilValue(isAuthenticatedSelector);
	const currentUser = useFragment<AppPageCurrentUserFragment$key>(
		graphql`
			fragment AppPageCurrentUserFragment on AuthCurrentUserWithErrors {
				__typename
				... on AuthError {
					__typename
				}
				... on User {
					__typename
				}
				...UserProfileCurrentUserFragment
			}
		`,
		appPageProps.pageProps.currentUser,
	);

	useEffect(() => {
		const refsFiber: eff.fiber.FiberContext<
			never,
			eff.array.Array<PreloadedQuery<OperationType>>
		> = eff.fn.pipe(
			queries,
			eff.array.map(({ mkVariables, query, relayOptions }) =>
				eff.t.promise(async () => {
					const variables =
						typeof mkVariables === "function"
							? ((await mkVariables({
									params: paramsRef.get,
									searchParams: searchParamsRef.get,
							  })) as Variables)
							: mkVariables || {};

					return loadQuery(relayEnvironment, query, variables, relayOptions);
				}),
			),
			eff.t.collectAllPar,
			eff.t.map(eff.chunk.toArray),
			eff.t.tap((refs) =>
				eff.t.succeedWith(() => {
					setQueryRefs({
						path: appPageProps.pageProps.path,
						refs: refs as QueryOptionsListToRefs<
							QueriesWithOptions<AppPageQueries<P>>
						>,
					});
				}),
			),
			eff.t.runFiber,
		);

		return () =>
			eff.fn.pipe(
				() => refsFiber,
				eff.t.fromFiber,
				eff.t.chain(
					(refs) =>
						eff.fn.pipe(
							refs,
							eff.array.map((queryRef) =>
								eff.t.succeedWith(() => {
									queryRef.dispose();
								}),
							),
							eff.array.sequence(eff.t.Applicative),
						) as eff.t.Effect<unknown, unknown, unknown>,
				),
				eff.t.run,
			);
	}, [
		queries,
		relayEnvironment,
		paramsRef,
		searchParamsRef,
		setQueryRefs,
		appPageProps.pageProps.path,
	]);

	return eff.fn.pipe(
		isSSR || queryRefs.path === appPageProps.pageProps.path,
		eff.bool.and(queryRefs.refs.length === queries.length),
		eff.bool.fold(
			() => appPageProps.pageProps.fallback || <div>Loading...</div>,
			() =>
				eff.fn.pipe(
					splitOptions({
						...appPageProps,
						path: appPageProps.pageProps.path,
						queryRefs: queryRefs.refs,
					} as PathWithOptions<Path>),
					eff.either.fold(
						(nonProtectedPageOpts) =>
							tsPattern
								.match(nonProtectedPageOpts)
								.with({ path: "/" }, () => (
									<MainPage className="test"></MainPage>
								))
								.with({ path: "/sign-in" }, (props) => (
									<SignIn {...props} />
								))
								.with({ path: "/sign-out" }, () => <SignOut />)
								.with({ path: "/form" }, () => <Form />)
								.with({ path: "/competition-rules" }, () => (
									<CompetitionRules />
								))
								.with({ path: "/privacy-policy" }, () => (
									<PrivacyPolicy />
								))
								.with({ path: "/cookie-policy" }, () => <CookiePolicy />)
								.with({ path: "/sales-conditions" }, () => (
									<SalesConditions />
								))
								.exhaustive(),
						(protectedPageOpts) =>
							eff.fn.pipe(
								isAuthenticated,
								eff.bool.fold(
									() => <NotAuthenticated />,
									() =>
										tsPattern
											.match(currentUser)
											.with({ __typename: "User" }, (currentUser) =>
												tsPattern
													.match(protectedPageOpts)
													.with(
														{ path: "/profile" },
														(props) => (
															<UserProfile
																{...props}
																currentUser={currentUser}
															/>
														),
													)
													.exhaustive(),
											)
											.otherwise(() => <NotAuthenticated />),
								),
							),
					),
				),
		),
	);
};
