import { TypedNavigate } from "@app/Router/TypedNavigate";
import { tokenState } from "@app/SignIn/SignIn";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

export const SignOut = () => {
	const [, setToken] = useRecoilState(tokenState);

	useEffect(() => {
		setToken({
			token: "",
			longTermSave: false,
			shortTermSave: false,
		});
	}, [setToken]);

	return <TypedNavigate to="/" />;
};
