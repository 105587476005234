import { TypedLink } from "@app/Router/TypedLink";
import * as styles from "./NotAuthenticated.css";

export const NotAuthenticated = () => {
	return (
		<div className={styles.container}>
			<div className={styles.innerContainer}>
				<div className={styles.errrorMessage}>You are not authenticated.</div>
				<div className={styles.signInLinkContainer}>
					<TypedLink className={styles.signInLink} to="/sign-in">
						Sign In
					</TypedLink>
				</div>
			</div>
		</div>
	);
};
